<template>  
        <div>
            <div class="typeServiceContainer">
                <div class="typeService">
                    <p class="typeServiceImage">Escolha aqui o tipo de serviço:</p>
                </div>
                <div class="typeButton">
                    <router-link to="/search" class="acompanhaButton">Acompanhar Pedido <img src="@/assets/headerImages/lupa.svg" class="lupaImg" alt=""/></router-link>
                </div>
            </div>
            <div class="bigTypeButtonContainer">
                <div class="typeServiceMobile">
                    <p class="typeServiceImage">Escolha aqui o tipo de serviço:</p>
                </div>
                <button @click="goTrue" class="bigButton" id="bigOne">
                <img src="@/assets/headerImages/forYoutext.svg" class="forYouText" alt="">
                    <div class="imgButtonContainer">
                        <img src="@/assets/headerImages/EllipseyYelowButton.svg" class="yellowElipse" alt="">
                        <img src="@/assets/headerImages/peopleButton.svg" class="peopleButton">
                        <img src="@/assets/headerImages/arrowButton.svg" :class="showDisplay ? 'arrowButton arrowButtonHover' : 'arrowButton'">
                    </div>
                </button>
                <button @click="goTrueCar" class="bigButton position-relative" id="bigTwo">
                    <img src="@/assets/headerImages/fotCarText.svg" class="forCarText">
                    <!-- <div class=" flex-column align-content-center">
                    </div> -->
                    <!--  -->
                    <div class="imgButtonContainer">
                        <img src="@/assets/headerImages/carImg.svg" class="carImg">
                        <img src="@/assets/headerImages/elipseBlueButtom.svg" class="elipseBlueButtom">
                        <img src="@/assets/headerImages/arrowButton.svg" :class="showCarDisplay ? 'arrowTwo arrowTwoHover' : 'arrowTwo'">
                    </div>
                    <p class="position-absolute bottom-0 textService">* Somente Estado de São Paulo</p>
                </button>
                <router-link to="/forYou" class="bigButton" id="bigOneTwo">
                <img src="@/assets/headerImages/forYoutext.svg" class="forYouText">
                    <div class="imgButtonContainer">
                        <img src="@/assets/headerImages/EllipseyYelowButton.svg" class="yellowElipse">
                        <img src="@/assets/headerImages/peopleButton.svg" class="peopleButton">
                        <img src="@/assets/headerImages/arrowButton.svg" :class="showDisplay ? 'arrowButton arrowButtonHover' : 'arrowButton'">
                    </div>
                </router-link>
                <router-link to="forCar" class="bigButton  position-relative" id="bigTwoTwo">
                    <img src="@/assets/headerImages/fotCarText.svg" class="forCarText" >
                    <div class="imgButtonContainer">
                        <img src="@/assets/headerImages/carImg.svg" class="carImg">
                        <img src="@/assets/headerImages/elipseBlueButtom.svg" class="elipseBlueButtom">
                        <img src="@/assets/headerImages/arrowButton.svg" class="arrowButton" id="arrowTwo">
                    </div>
                    <p class="position-absolute bottom-0 textService">* Somente Estado de São Paulo</p>
                </router-link>
            </div>
            <div class="typeButtonMobileContainer">
                <div class="typeButtonMobile">
                    <router-link to="/search" class="acompanhaButtonMobile">Acompanhar Pedido <img src="@/assets/headerImages/lupa.svg" class="lupaImg"/></router-link>
                </div>
            </div>
            <dropdown-servicos-vue :services="peopleServices" v-show="showDisplay" ></dropdown-servicos-vue>
            <dropdown-car-vue :services="carServices" :class="showCarDisplay ? ' ' : 'displayShow'"></dropdown-car-vue>
        </div>
</template>
<script>
import { HTTP } from "@/api_system"
import dropdownServicosVue from './dropdownServicos.vue';
import dropdownCarVue from './dropdownCar.vue';
import { mapGetters, mapMutations } from "vuex";
export default {
    components: {
        dropdownServicosVue,
        dropdownCarVue
    },
    data() {
        return {
            showDisplay: false,
            showCarDisplay:false,
            // token:"",
            peopleServices:[],
            carServices:[]
        };
    },
    methods:{
        ...mapMutations(["setToken"]),
        goTrue(){
            this.showDisplay = !this.showDisplay
        },
        goTrueCar(){
                this.showCarDisplay = !this.showCarDisplay
        },
        InicializaToken(){
            HTTP.post("services/app/PixBB/OAuthGenerateToken")
            .then(response => {
                let result = response.data.result
                this.setToken(result.access_token);
                this.getServices()
            })
            .catch(error => {
                console.log(error);
            });
        },
        getServices(){
            HTTP.get("services/app/PixBB/GetServices", {
                headers: {
                    Authorization: this.getToken
                }
            })
            .then(response => {
                this.peopleServices = response.data.result.servicesForPeople;   
                this.carServices = response.data.result.servicesForVehicle;  
            })
            .catch(error => {
                console.log(error);
            });
        },
        
    },
    mounted(){
        this.InicializaToken()
    },
    computed: {
        ...mapGetters(["getToken"])
    }
}
</script>

<style scoped>
.textService {
    font-style: italic;
    color: #fff;
    font-weight: 700;
    font-size: 1rem;
    margin: 20px;
}

.containerImgText {
    display: flex;
    align-items: center;
}
.dropdown{
    display: none;
}
@media screen and (min-width:2000px){
    .bigButton{
        width: 19.5%!important;
        max-width: 19.5%!important;
    }
    .elipseBlueButtom{
        left: 52px!important;
        bottom: 79px!important;
    }
    #arrowTwo{
        top: -39px!important;
    }
    .carImg{
        top: 37px!important;
        left: 23px!important;
    }
    .yellowElipse {
        left: 116px!important;
    }
    .arrowButton{
            top: 38px!important;
    }
    .peopleButton{
        left: -14px!important;
    }
}
@media screen and (max-width:1440px){
    .carImg {
        top: 29px!important;
        left: 0px!important;
    }
    .arrowTwo {
        top: -36px!important;
    }
    .elipseBlueButtom {
        left: 110px!important;
        bottom: 78px!important;
        max-width: 47%!important;
    }
    .yellowElipse {
        left: 11px!important;
        max-width: 47%!important;
    }
    .peopleButton{
        left: 125px!important;
        bottom: 118px!important;
    }
    .arrowButton {
        top: -100px!important;
    }
}
@media screen and (max-width:1300px){
    .acompanhaButton{
        height: 35px!important;
    }
}
@media screen and (max-width:1280px){
    .bigButton {
        width: 29.5%!important;
        border: none!important;
        border-radius: 8px!important;
        height: 145px!important;
        cursor: pointer!important;
        max-width: 28.5%!important;
    }  
}
@media screen and (max-width:1024px){
    .forCarText{
        padding-top: 8.5%!important;
        max-width: 52%!important;
    }
    .bigButton {
        width: 29.5%!important;
        max-width: 34.5%!important;
        height: 115px!important;
    }
    .carImg {
        top: 29px!important;
        left: 0px!important;
    }
    .arrowTwo{
        top: -155px!important;
    }
    .elipseBlueButtom {
        left: 3px!important;
        bottom: 75px!important;
        max-width: 45%!important;
    }
    .yellowElipse{
        left: 7px!important;
        top: 1px!important;
        max-width: 45%!important;
    }
    .peopleButton {
        left: 94px!important;
        bottom: 103px!important;
    }
    .arrowButton{
        top: -84px!important;
    }
    .carImg {
        top: 22px!important;
        left: -5px!important;
        max-width: 29%!important;
    }
}
@media screen and (max-width:913px){
    .bigButton {
        width: 33.5%!important;
        max-width: 34.5%!important;
        height: 145px!important;
    }
}
@media screen and (max-width:820px){
    .elipseBlueButtom {
        left: 6px!important;
        bottom: 75px!important;
        max-width: 44%!important;
    }
    .bigButton {
        width: 37.5%!important;
        max-width: 37.5%!important;
        height: 145px!important;
    }
    .arrowTwo{
        top: -154px!important;
    }
}
@media screen and (max-width:800px){
    .acompanhaButton{
        height: 29px!important;
        width: 120%!important;
        font-size: 1.2vw!important;
    }
}
@media screen and (max-width:768px){
    .forCarText{
        padding-top: 7.5%!important;
        max-width: 52%!important;
    }
    .bigButton {
        width: 29.5%!important;
        max-width: 34.5%!important;
        height: 145px!important;
    }
    .carImg {
        top: 29px!important;
        left: 0px!important;
    }
    .arrowTwo{
        top: -36px!important;
        width: 9%!important;
    }
    .elipseBlueButtom {
        left: 71px!important;
        bottom: 56px!important;
        max-width: 45.5%!important;
    }
    .yellowElipse{
        left: 7px!important;
        top: 1px!important;
        max-width: 45%!important;
    }
    .peopleButton {
        max-width: 22%!important;
        left: 72px!important;
        bottom: 80px!important;
     }
    .arrowButton{
        top: -66px!important;
        max-width: 9%!important;
    }
    
    .carImg {
        top: 14px!important;
        left: -5px!important;
        max-width: 29%!important;
    }
}
@media screen and (min-width:601px){
    .typeServiceMobile{
        display: none!important;
    }
    .typeButtonMobileContainer{
        display: none!important;
    }
    .typeButtonMobile{
        display: none!important;
    }
}
@media screen and (min-width:601px) {
    #bigOneTwo{
        display: none!important;
    }
    #bigTwoTwo{
        display: none!important;
    }
}
@media screen and (max-width:600px){
    #bigOne{
        display: none!important;
    }
    #bigTwo{
        display: none!important;
    }
    .typeButton{
        display: none!important;
    }
    .bigTypeButtonContainer{
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .bigButton {
        width: 55.5%!important;
        max-width: 55.5%!important;
        height: 145px!important;
    }
    #bigTwo{
        margin-left: 0!important;
        margin-top: 1%;
    }
    .yellowElipse {
        left: 98px!important;
        top: 1px!important;
        max-width: 29%!important;
    }
    .peopleButton {
        max-width: 14%!important;
        left: 17px!important;
        bottom: -2px!important;
    }
    .arrowButton {
        top: 11px!important;
        max-width: 9%!important;
    }
    .forYouText{
        max-width: 34%!important;
        padding-top: 4.5%!important;
    }
    .elipseBlueButtom {
        left: 35px!important;
        top: 1px!important;
        max-width: 29%!important;
    }
    .carImg {
        max-width: 18%!important;
        left: 104px!important;
        top: 3px!important;
    }
    #arrowTwo {
        top: 11px!important;
        max-width: 8%!important;
    }
    .forCarText{
        max-width: 39%!important;
        padding-top: 6.5%!important;
    }
    .typeService{
        display: none;
    }
    .typeServiceContainer{
        display: none!important;
    }
    .typeServiceMobile{
        padding-top: 10%;

    }
}
@media screen and (max-width:540px){
    .yellowElipse {
        left: 102px!important;
        top: 0px!important;
        max-width: 32%!important;
    }
    .peopleButton {
        max-width: 14%!important;
        left: 23px!important;
        bottom: -2px!important;
    }
    .elipseBlueButtom {
        left: 35px!important;
        top: 0px!important;
        max-width: 31.5%!important;
    }
    .carImg {
        max-width: 18%!important;
        left: 98px!important;
        top: 3px!important;
    }
}
@media screen and (max-width: 414px){
    .forCarText{
        max-width: 39%!important;
        padding-top: 7.5%!important;
    }
    #arrowTwo {
        top: 19px!important;
        max-width: 8%!important;
        }
    .forYouText {
        max-width: 34%!important;
        padding-top: 6.5%!important;
    }
    .arrowButton {
        top: 19px!important;
        max-width: 9%!important;
    }
    .bigButton{
        width: 80.5%!important;
        max-width: 82.5%!important;
        height: 145px!important;
    }
    .elipseBlueButtom {
        left: 30px!important;
        top: 0px!important;
        max-width: 34%!important;
    }
    .yellowElipse{
        left: 106px!important;
        top: 0px!important;
        max-width: 34%!important;
    }
    .peopleButton {
        max-width: 14%!important;
        left: 10px!important;
        bottom: -2px!important;
    }
    .carImg {
        max-width: 18%!important;
        left: 101px!important;
        top: 3px!important;
    }
}
@media screen and (max-width: 393px){
    .peopleButton {
        max-width: 14%!important;
        left: 6px!important;
        bottom: -2px!important;
    }
    .yellowElipse {
        left: 93px!important;
        top: 0px!important;
        max-width: 36%!important;
    }
    .elipseBlueButtom {
        left: 24px!important;
        top: 0px!important;
        max-width: 35%!important;
    }
    .carImg {
    left: 100px!important;
    }
}
@media screen and (max-width:376px){
    .forYouText{
        max-width: 34%!important;
        padding-top: 6.5%!important;
    }
    .forCarText {
        max-width: 39%!important;
        padding-top: 8.5%!important;
    }
    .bigButton {
        width: 88.5%!important;
        max-width: 87.5%!important;
        height: 145px!important;
    }
    .carImg {
        left: 102px!important;
    }
    .yellowElipse   {
        left: 100px!important;
        top: 0px!important;
        max-width: 35.5%!important;
    }
    .peopleButton {
        max-width: 15%!important;
        left: 9px!important;
        bottom: -2px!important;
    }
    .arrowButton{
        top: 19px!important;
        max-width: 9%!important;
    }
    #arrowTwo {
        top: 20px!important;;
        max-width: 8%!important;
    }
    .elipseBlueButtom {
        left: 26px!important;
        top: 0px!important;
        max-width: 35%!important;
    }
}  
@media screen and (max-width:360px){
    .bigButton {
        width: 88.5%!important;
        max-width: 87.5%!important;
        height: 145px!important;
    }
    .carImg {
        left: 106px!important;
    }
    .yellowElipse   {
        left: 113px!important;
        top: 1px!important;
        max-width: 30%!important;
    }
    .peopleButton {
        max-width: 14%!important;
        left: 32px!important;
        bottom: -2px!important;
    }
    .elipseBlueButtom {
        left: 39px!important;
        top: 0px!important;
        max-width: 30.5%!important;
    }
    .arrowButton{
        top: 12px!important;
        max-width: 9%!important;
    }
    #arrowTwo {
        top: 14px!important;
        max-width: 8%!important;
    }
} 
@media screen and (max-width:280px){
    .yellowElipse {
        left: 65px!important;
        top: 1px!important;
        max-width: 39%!important;
    }
    .peopleButton {
        max-width: 18%!important;
        left: -9px!important;
        bottom: -2px!important;
    }
    .arrowButton {
        top: 17px!important;
        max-width: 9%!important;
    }
    .elipseBlueButtom{
        left: 2px!important;
        top: 0px!important;
        max-width: 39.5%!important;
    }
    .carImg{
        left: 72px!important;
        max-width: 21%!important;
    }
    #arrowTwo {
        top: -62px!important;
        max-width: 8%!important;
    }
} 
.arrowTwo{
    position: relative;
    float: right;
    padding-top: 5%;
    padding-right: 3%;
    max-width: 40%;
    transition: 0.4s ease-in-out;
    top: -44px;
}
.arrowTwoHover{
    transform: rotate(90deg);
    margin-top: 4%;
}
.arrowTwo{
    position: relative;
    float: right;
    padding-top: 5%;
    padding-right: 3%;
    max-width: 40%;
    transition: 0.4s ease-in-out;
    top: -44px;
}
.arrowButton{
    position: relative;
    float: right;
    padding-top: 5%;
    padding-right: 3%;
    top:37px;
    max-width: 40%;
    transition: 0.4s ease-in-out;
}

.arrowButtonHover{
    transform: rotate(90deg);
    margin-top: 4%;
}
.rectangle{
   position: absolute;
   width: 80%;
   max-width: 80%;
    max-height: 80%;
}

.typeService{
    padding-top: 3%;
    width: 57%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10%;
}
.typeServiceMobile{
    padding-top: 3%;
    width: 57%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 5%;
    margin-top: 5%;
}
.typeServiceContainer{
    padding-top: 4%;
    background-color: rgb(241, 241, 241);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
    padding-bottom: 8%;
}
.typeButton{
    padding-top: 3%;
    width: 13%;
}
.typeButtonMobileContainer{
    margin-top: 4%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 5%;
}
.typeButtonMobile{
    padding-top: 3%;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    font-size: max(1.2vw, 14px);
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}
.typeServiceImage{
    font-style: normal;
    font-weight: 700;
    font-size: max(1.8vw, 27px);
    line-height: 130%;
    color: #18408A;
    text-align: center;
}
.acompanhaButtonMobile{
    height: 45px!important;
    width: 120%!important;
    font-size: 3.2vw!important;
    background: #71A1FF;
    border-radius: 30px;
    font-weight: 600;
    color: #FFF;
    gap: 2px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
}
.acompanhaButton{
    width: 100%;
    height: 45px;
    background: #71A1FF;
    border-radius: 30px;
    font-weight: 600;
    font-size: 1vw;
    color: #FFF;
    gap: 2px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    text-decoration: none;
}
.acompanhaButton:hover{
    background: #0452ee;
    transition: 0.7s;
}
.bigTypeButtonContainer{
    width: 100%;
    display: flex;
    justify-content: center;  
    position: relative;
    margin-top: -3%  
}
.bigButton{
    width: 25.5%;
    border: none;
    border-radius: 8px;
    height: 145px;
    cursor: pointer;
    max-width: 25.5%;
}
.imgButtonContainer{
    position: relative;
    height: 100%;
}
#bigOne{
    background:  linear-gradient(90deg, #FFA620 36.48%, rgba(255, 166, 32, 0) 108.81%);
    mix-blend-mode: normal;
}
#bigOneTwo{
    background:  linear-gradient(90deg, #FFA620 36.48%, rgba(255, 166, 32, 0) 108.81%);
    mix-blend-mode: normal;
    margin-left: 3%;
}
#bigTwo{
    background: #465EFF;
    margin-left: 3%;
}
#bigTwoTwo{
    margin-top: 2%;
    background: #465EFF;
    margin-left: 3%;
}
.forYouText{
    float: left;
    padding-left: 5%;
    padding-top: 5.5%;
    max-width: 50%;
}
.peopleButton{
    position: relative;
    max-width: 40%;
}

.yellowElipse{
    left: 116px;
    position: relative;
    max-width: 40%;
}
.elipseBlueButtom{
    left: 50px;
    bottom: 79px;
    position: relative;
    max-width: 40%;
}
.forCarText{
    float: left;
    padding-left: 5%;
    padding-top: 5.5%;
    max-width: 50%;
}
.carImg{
    position: relative;
    top: 35px;
    left: 27px;
    max-width: 40%;
}

.displayShow {
    display: none;
}
</style>