<template>
<div>
  <div class="wrapper-stepper">
    <div class="stepper">
      <div class="stepper-progress">
        <div class="stepper-progress-bar" :style="'width:' + stepperProgress "></div>
      </div>

      <div class="stepper-item" :class="{ 'current': step == item, 'success': step > item }" v-for="item in amount" :key="item">
        <div class="stepper-item-counter">
          <img class="icon-success" src="https://www.seekpng.com/png/full/1-10353_check-mark-green-png-green-check-mark-svg.png" alt="">
          <span>
          </span>
        </div>
        <!-- <span class="stepper-item-title">
                    Paso {{ item }}
                </span> -->
      </div>
    </div>

    <!-- <div class="stepper-content" v-for="item in 4" :key="item">
            <div class="stepper-pane" v-if="step == item">
                Estas en el paso <span class="tx-green-1">{{ item }}</span> aqui mostrarás el contenido :D
            </div>
        </div> -->

    <!-- <div class="controls">
            <button class="btn" @click="step--" :disabled="step == 1">
                Anterior
            </button>
            <button class="btn btn--green-1" @click="step++" :disabled="step == amount">
                Siguiente
            </button>
        </div> -->
  </div>
</div>
</template>

<script>
export default {
  props: {
    valueStep: {
      type: Number
    },
  },
  data: () => ({
    step: 1,
    amount: 4
  }),
  computed: {
    stepperProgress() {
      return (100 / (this.amount - 1)) * (this.step - 1) + '%'
    }
  },
  watch: {
    valueStep: function (value) {
      this.step = value
    },
  },
}
</script>

<style scoped>
/* body {
  background-image: linear-gradient(60deg, #abecd6 0%, #fbed96 100%);
  color: #fff;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
} */
.tx-green-1 {
  color: #c4ffd8;
  font-weight: 600;
}

.wrapper-stepper {
  background-color: #fff;
  padding: 2px;
  border-radius: 32px;
  box-shadow: rgba(0, 0, 0, 0.09);
}

.stepper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 300px;
  position: relative;
  z-index: 0;
  margin-bottom: 24px;
}

.stepper-progress {
  position: absolute;
  background-color: #f4f4f8;
  height: 2px;
  z-index: -1;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.stepper-progress-bar {
  position: absolute;
  left: 0;
  height: 100%;
  width: 0%;
  background-color: #c4ffd8;
  transition: all 500ms ease;
}

.stepper-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #f4f4f8;
  transition: all 500ms ease;
}

.stepper-item-counter {
  /* height: 68px;
  width: 68px; */
  height: 31px;
  width: 31px;
  display: grid;
  place-items: center;
  background-color: #fff;
  border-radius: 100%;
  border: 2px solid #f4f4f8;
  position: relative;
}

.stepper-item-counter .icon-success {
  position: absolute;
  opacity: 0;
  transform: scale(0);
  width: 15px;
  transition: all 500ms ease;
}

.stepper-item.current span {
  height: 10px;
  width: 10px;
  background: #1e59ae;
  border-radius: 50%;
}

/* .stepper-item-counter span {
  height: 10px;
  width: 10px;
  background: #1e59ae;
  border-radius: 50%;
} */
.stepper-item-counter span::before {
  content: ' ';
  background-color: red;
  width: 20px;
  height: 20px;
}

.stepper-item-title {
  position: absolute;
  font-size: 14px;
  bottom: -24px;
}

.stepper-item.success .stepper-item-counter {
  border-color: #c4ffd8;
  background-color: #c4ffd8;
  color: #fff;
  font-weight: 600;
}

.stepper-item.success .stepper-item-counter .icon-success {
  opacity: 1;
  transform: scale(1);
}

.stepper-item.success span {
  width: 0;
  height: 0;
}

.stepper-item.success .stepper-item-counter .number {
  opacity: 0;
  transform: scale(0);
}

.stepper-item.success .stepper-item-title {
  color: #c4ffd8;
}

.stepper-item.current .stepper-item-counter {
  border-color: #f4f4f8;
  background-color: #f4f4f8;
  color: #fff;
  font-weight: 600;

  height: 31px;
  width: 31px;
  /* background: #f4f4f8;
  border-color: #f4f4f8; */
}

.stepper-item.current .stepper-item-title {
  color: #818181;
}

.stepper-pane {
  color: #333;
  text-align: center;
  padding: 120px 60px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.09);
  margin: 40px 0;
}

.controls {
  display: flex;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  border: 1px solid;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  line-height: 1.5;
  transition: all 150ms;
  border-radius: 4px;
  width: fit-content;
  font-size: 0.75rem;
  color: #333;
  background-color: #f0f0f0;
  border-color: #f0f0f0;
}

.btn:disabled {
  opacity: 0.5;
  pointer-events: none;
}

.btn--green-1 {
  background-color: #c4ffd8;
  border-color: #c4ffd8;
  color: #fff;
  margin-left: auto;
}

@media (min-width: 768px) {



  .stepper {
    width: 660px;
  }

  .stepContainer {
    justify-content: initial;
  }
  
}
</style>
