<template>
    <div >
        <header>
            <other-components-header></other-components-header>
        </header>
        <body>
            <div class="resultContainer">
                <h2 class="acompanhaTexto">Resultado da Pesquisa</h2>
                <div class="infoContainer">
                    <div class="teste">
                        <div class="informacao">
                            <div class="id">
                                <p :title="item.result.orderId"><strong>Pedido:</strong> {{item.result.orderId}}</p>
                            </div>
                            <div class="cpf">
                                <p><strong>CPF:</strong> {{item.result.cpf}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="servicoDesc">
                        <p class="desc"><strong>Serviço:</strong> {{item.result.serviceName }}</p>
                    </div>
                    <div class="statusContainerProgress">
                        <div class="servicoStatusProgress">
                            <img src="@/assets/allImages/inProgress.svg" class="inProgressImg">
                            <p class="emProg"><strong> {{ item.result.status }}</strong></p>
                        </div>
                    </div>
                    <div class="awaitDiv">
                        <p class="await">Aguarde mais alguns minutos!</p>
                    </div>
                    <div class="statusContainerFinaly">
                        <div class="servicoStatusFinaly">
                            <img src="@/assets/allImages/finalyProgress.svg" class="finalyProgressImg">
                            <p class="emProg"><strong>Finalizado</strong></p>
                        </div>
                    </div>
                    <div class="awaitDivFinaly">
                        <p class="await">Eba! Seu pedido foi finalizado.</p>
                    </div>
                </div>
            </div>
            <div class="containerButtonMessage">
                <div class="buttonContainerMessage">
                    <button class="mensageButton" data-bs-toggle="modal" data-bs-target="#exampleModal">Dúvidas? Nos envie uma mensagem
                        <img src="@/assets/bodyImages/WHATSAPP.svg" class="wppIcon">
                    </button>
                </div>
            </div>
            <div class="realizeContainer">
                <router-link to="/" class="realizeNew">Solicitar novo serviço!</router-link>
            </div>
            <modal-wpp></modal-wpp>
        </body>
        <footer>
            <footer-in-footer></footer-in-footer>
        </footer>
    </div>
    
</template>



<script>
    import modalWpp from '@/utils/modalWpp.vue'
    import otherComponentsHeader from '@/components/Header/otherComponentsHeader.vue'
    import footerInFooter from '@/components/Footer/footerInFooter.vue';
    import { mapGetters } from 'vuex';
export default {
    components:{
        otherComponentsHeader,
        footerInFooter,
        modalWpp
    },
    data(){
        return{

        }
    },
    computed: {
        ...mapGetters({
            item: 'getSearchService'
        })
    },
    methods:{
        verifyProgress(){
            if(this.$store.state.search.id === "123456"){
                let containerImg = document.querySelector(".statusContainerProgress")
                let containerWord = document.querySelector(".awaitDiv")
                containerImg.style.display = 'flex'
                containerWord.style.display = 'flex'
            }else if(this.$store.state.search.id === "456123"){
                let servico = document.querySelector('.servicoDesc')
                let containerImg = document.querySelector(".statusContainerFinaly")
                let containerWord = document.querySelector(".awaitDivFinaly")
                containerImg.style.display = 'flex'
                containerWord.style.display = 'flex'
                servico.style.display = 'none'
            }else{
                let containerImg = document.querySelector(".statusContainerProgress")
                let containerWord = document.querySelector(".awaitDiv")
                containerImg.style.display = 'flex'
                containerWord.style.display = 'flex'
            }
        }
    },
    mounted(){
        this.verifyProgress()
    }
}
</script>
<style scoped>
@media screen and (max-width:1330px){
    .id p{
        white-space: nowrap!important;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
@media screen and (max-width:820px){
    .id {
        width: 21%!important;
        padding-top: 0.6%!important;
    }
    .cpf{
        width: 21%!important;
        padding-top: 0.6%!important;
    }
    .servicoStatusProgress {
        margin-top: 3%!important;
        width: 55%!important;
        justify-content: center!important;
        align-content: center!important;
        height: 65px!important;
    }
    .servicoStatusFinaly {
        margin-top: 3%!important;
        width: 55%!important;
        justify-content: center!important;
        align-content: center!important;
        height: 65px!important;
    }
}
@media screen and (max-width:600px){
    .cpf {
        width: 43%!important;
        height: 47px!important;
        margin-top: 5%!important;
        margin-left: 0!important;
        padding-top: 1%!important;
    }
    .informacao{
        width: 100%!important;
        display: flex!important;
        justify-content: center!important;
        flex-direction: column!important;
        align-items: center!important;
        }
    .id{
        width: 43%!important;
        font-size: 16px!important;
        height: 47px!important;
        padding-top: 1%!important;
    }
    p{
        padding-top: 4%!important;
    }
    .statusContainerProgress {
        justify-content: center!important;
        align-content: center!important;
        width: 100%!important;
    }
    .statusContainerFinaly {
        justify-content: center!important;
        align-content: center!important;
        width: 100%!important;
    }
    .await {
        margin-bottom: 8%!important;
    }
}
@media screen and (max-width:410px){
    .cpf{
        width: 52%!important;
        font-size: 18px!important;
    }
    .id{
        width: 52%!important;
        font-size: 18px!important;
    }
    .servicoStatusProgress {
        width: 75%!important;
    }
    .servicoStatusFinaly {
        width: 75%!important;
    }
    .buttonContainerMessage{
        width: 100%!important;
        display: flex!important;
        align-items: center!important;
        justify-content: center!important;
    }
    .mensageButton[data-v-3d9a52e9] {
        font-size: 13px!important;
        width: 80%!important;
    }
    
}
@media screen and (max-width:340px){
    .cpf{
        width: 52%!important;
        font-size: 16px!important;
        padding-top: 1%!important;
    }
    .id{
        width: 52%!important;
        font-size: 16px!important;
        padding-top: 1%!important;
    }
}
@media screen and (max-width:300px){
    .cpf{
        width: 52%!important;
        font-size: 12px!important;
        padding-top: 1%;
        height: 40px!important;
    }
    .id{
        width: 52%!important;
        font-size: 12px!important;
        padding-top: 1%;
        height: 40px!important;
    }
}


h2{
    padding-top: 5%;
}
p{
    padding-top: 3%;
}
.acompanhaTexto{
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 45px;
    text-align: center;
    color: #F38235;
}
.servicoDesc{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.mensageButton{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 22px;
    gap: 8px;
    background-color: #FFFFFF;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 113.2%;
    color: #18408A;
    flex: none;
    order: 0;
    flex-grow: 0;
    width: 384px;
    max-height: 54px;
    border: 1px #d1d1d1 solid;
}
.mensageButton:hover{
    background: #00BB2D;
    border: none;
    transition: 0.6s;
    color: #FFFF;
}
.awaitDiv{
    display: none;
    justify-content: center;
    align-content: center;
}
.awaitDivFinaly{
    display: none;
    justify-content: center;
    align-content: center;
}
.await{
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #797984;
    padding-top: 25px!important;
}
    
.wppIcon{
    padding-left: 1.5%;
}
.containerButtonMessage{
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 5%;
}
.infoContainer{
    margin-top: 3%;
    display: flex;
    justify-content: center;
    align-content: center;
    width: 100%;
    flex-direction: column;
}
.cpf{
    width: 18%;
    margin-left: 2%;
    background: #FFFFFF;
    border: 2px solid #E9E9EB;
    border-radius: 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4C4D5B;
    padding-left: 1%;
    height: 47px;

}
.id{
    background: #FFFFFF;
    border: 2px solid #E9E9EB;
    border-radius: 20px;
    width: 18%;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #4C4D5B;
    padding-left: 1%;
    height: 47px;

}
.informacao{
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: row;
}
p{
    margin: 0;
}
.statusContainerProgress{
    display: none;
    justify-content: center;
    align-content: center;
    width: 100%;
}
.statusContainerFinaly{
    display: none;
    justify-content: center;
    align-content: center;
    width: 100%;
}
.servicoStatusProgress{
    background: #FFF5D0;
    border-radius: 16px;
    margin-top: 3%;
    width: 25%;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 65px;
}
.servicoStatusFinaly{
    background: #C4FFD8;
    border-radius: 16px;
    margin-top: 3%;
    width: 25%;
    display: flex;
    justify-content: center;
    align-content: center;
    height: 65px;
}
.emProg{
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    line-height: 36px;
    color: #4C4D5B;
}
.desc{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #A5A6AD; 
    padding-top: 25px!important;
}
.inProgressImg{
    margin-right: 2%;
    max-width: 13%;
}
.finalyProgressImg{
    margin-right: 2%;
    max-width: 10%;
}
.realizeContainer{
    margin-top: 3%;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-bottom: 4%;
}
.realizeNew{
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    color: #FFA620;
    flex: none;
    order: 0;
    flex-grow: 0;
    text-decoration: none;
}
@media screen and (max-width:600px){
    .realizeContainer{
        margin-bottom: 12%;
    }
}
</style>