<template>
    <div>
        <forms-footer-vue id="formsFooter"></forms-footer-vue>
        <footer-in-footer></footer-in-footer>
    </div>
</template>

<script>
import formsFooterVue from './formsFooter.vue';
import footerInFooter from './footerInFooter.vue'
export default {
    components:{
        formsFooterVue,
        footerInFooter
    }
}
</script>

<style scoped>


</style>