<template> 
    <div>
        <whats-app-fixed></whats-app-fixed>
        <other-components-header></other-components-header>
        <div class="allContainer">
            <div class="titleContainer">
                <p class="title">{{$store.state.service.serviceName}}</p>
            </div>
            <div class="stepContainer">
                <router-link to="/secondStep" class="setaStep"><img src="@/assets/stepsImages/setaStep.svg" alt=""></router-link>
                <div class="stepTree"><img src="@/assets/stepsImages/stepTree.svg" class="stepTreeImg" alt=""></div>
            </div>
            <div class="qrCodeTextContainer" id="desk"><p class="qrCodeText">Utilize o <strong>QRcode</strong> ou <strong>copie o código PIX</strong> para efetuar o pagamento.</p></div>
            <div class="qrCodeTextContainer" id="mobile"><p class="qrCodeText"><strong>Copie o código PIX</strong> para efetuar o pagamento.</p></div>
            <div class="valueContainer"><p class="valueDesc">Valor do Serviço:</p><p class="valueValue"> {{$store.state.service.serviceValue}}</p></div>
            <div class="payContainer">
                <div class="qrContainer">
                    <img id="qrImage" alt="">
                    <p class="pixText">PIX copia e cola:</p>
                </div>
                <div class="codeContainer">
                    <div class="pixTextMobile"><p>PIX copia e cola:</p></div>
                    <div class="pixCode">
                        <p id="texPixCode">{{ $store.getters.getQRCodeStore.qrCode }}</p>
                    </div>
                    <div @click="copyPixTxt" class="pixCopyText">
                        <div><a>Copiar código Pix</a></div>
                        <div class="orangeVIconContainer"><img src="@/assets/stepsImages/orangeVIcon.png" class="orangeVIcon" alt=""></div>
                    </div>
                </div>
            </div>
            <div class="buttonContainerSearch">
                    <router-link to="/lastStep" class="searchButton">Após o pagamento, clique aqui</router-link>
            </div>
            
        </div>
        <div>
            <footer-in-footer></footer-in-footer>
        </div>
    </div>
</template>

<script>
import whatsAppFixed from '@/utils/whatsAppFixed.vue';
import otherComponentsHeader from '@/components/Header/otherComponentsHeader.vue';
import footerInFooter from '@/components/Footer/footerInFooter.vue';
export default {
    components:{
        otherComponentsHeader,
        footerInFooter,
        whatsAppFixed
    },
    data(){
        return{

        }
    },
    methods:{
        copyPixTxt(){
            let textCopy = document.getElementById("texPixCode");
            navigator.clipboard.writeText(textCopy.innerHTML).then(() => {
                let orangeIcon = document.querySelector(".orangeVIconContainer")
                orangeIcon.style.display = 'block'
            })
        },
        qrCode(){
            let googleChartApi = `https://chart.googleapis.com/chart?cht=qr&chs=260x260&chl=https://${this.$store.getters.getQRCodeStore.qrCode}`;
            document.querySelector('#qrImage').src = googleChartApi
        }
    },
    mounted(){
        this.qrCode()
    }
}
</script>

<style scoped>
@media screen and (max-width:650px){
    .pixCode {  
        width: 55%!important;
    }
}
@media screen and (max-width:600px){
    #desk{
        display: none;
    }
    .pixCopyText{
        padding-top: 3%!important;
    }
    .payContainer{
        width: 70%!important;
    }
    .codeContainer{
        display: flex;
        flex-direction: column!important;
        align-items: center;
        justify-content: center;
    }
    .pixCode {  
        width: 70%!important;
        font-size: max(3.2vw, 14px)!important;
    }
    .qrContainer{
        display: none!important;
    }
    .searchButton {
        width: 63%!important;
        height: 30px!important;
    }
    .titleContainer {
        width: 73%!important;
        margin-bottom: 3%!important;
    }
    .title {
        font-size: max(1.3vw, 20px)!important;
    }
    .stepTree {
        margin-right: 4%!important;
        width: 80%!important;
    }
    .stepTreeImg{
        width: 90%!important;
    }
    .setaStep {
        margin-right: 9%!important;
    }
    .buttonContainerSearch{
        margin-bottom: 14%!important;
    }    
    .allContainer{
        margin-bottom: 25%!important;
    }
}
@media screen and (min-width:601px){
    .pixTextMobile{
        display: none!important;
    }
    #mobile{
        display: none!important;
    }
}
@media screen and (max-width:450px){
    .titleContainer {
        width: 95%!important;
    }
}
@media screen and (max-width:420px){
    .stepTree {
        margin-right: -6%!important;
        width: 80%!important;
    }
    .stepTreeImg{
        width: 82%!important;
    }
    .setaStep {
        margin-right: 6%!important;
    }
}
@media screen and (max-width:335px){
    .title {
        font-size: max(1.3vw, 16px)!important;
    }
}   
@media screen and (max-width:350px){
    .pixCode {
        width: 70%!important;
        font-size: max(3.2vw, 12px)!important;
    }
}  
.orangeVIconContainer{
    max-width: 20px;
    margin-left: 10px;
    display: none;
}
.orangeVIcon{
    width: 100%;
    padding-bottom: 5px;
}
.pixTextMobile{
    padding-bottom: 1%;
    margin-top: 2%;
    font-style: normal;
    font-weight: 400;
    font-size: max(1.3vw, 14px);
    line-height: 24px;
    color: #797984;
}

.titleContainer{
    display: flex;
    justify-content: center;
    background: #F7F7FB;
    border-radius: 24px;
    width: 30%;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 45px;
    text-align: center;
    color: #465EFF;
    height: 50px;
}
.allContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
    flex-direction: column;
}
.stepContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
}
.stepTree{
    margin-right: 43%;
}
.setaStep{
    margin-right: 43%;
}
.title{
    margin: 0;
    font-size: max(1.3vw, 12px);
}
@media screen and (max-width:800px){
    .setaStep{
        margin-right: 20%;
    }
    .stepTree{
     margin-right: 20%;
    }
}
.qrCodeTextContainer{
    margin-top: 3%;
    margin-bottom: 3%;
}
.qrCodeText{
    font-style: normal;
    font-weight: 400;
    font-size: max(1.5vw, 12px);
    line-height: 24px;
    text-align: center;
    color: #1E59AE;
    margin-top: 2%;
}
.searchButton{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 22px;
    gap: 10px;
    background: #FFA620;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: max(1.3vw, 12px);
    line-height: 113.2%;
    color: #FFFFFF;
    border: none;
    width: 30%;
    max-height: 49px;
    text-decoration: none;
}
.searchButton:hover{
    background: #f99500;
    transition: .7s;
}
.buttonContainerSearch{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 9%;
}
.valueContainer{
    margin-top: 2%;
    display: flex;
    flex-direction: row;
}
.valueDesc{
    font-style: normal;
    font-weight: 400;
    font-size: max(1.5vw, 12px);
    line-height: 27px;
    text-align: center;
    color: #797984;
    padding-right: 5px;
}
.valueValue{
    font-style: normal;
    font-weight: 700;
    font-size: max(1.5vw, 12px);
    line-height: 27px;
    text-align: center;
    color: #F38235;
}
.payContainer{
    display: flex;
    justify-content: center;
    margin-top: 2%;
    border: 1px solid #E9E9EB;
    border-radius: 12px;
    width: 40%;
    flex-direction: column;
}
.qrContainer{
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pixText{
    padding-bottom: 1%;
    margin-top: 2%;
    font-style: normal;
    font-weight: 400;
    font-size: max(0.9vw, 11px);
    line-height: 24px;
    color: #797984;
}
.codeContainer{
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-bottom: 3%;
}
.pixCode{
    display: flex;
    justify-content: center;
    background: #f1f1f1;
    opacity: 0.8;
    border-radius: 20px;
    width: 43%;
    align-items: center;
    margin-bottom: 0;
    font-style: normal;
    font-weight: 300;
    font-size: max(1.2vw, 9px);
    line-height: 24px;
    color: #4b4b4c;
    flex-direction: row;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.pixCopyText{
    display: flex;
    flex-direction: row;
    margin-left: 2%;
    font-style: normal;
    font-weight: 600;
    font-size: max(1.1vw, 12px);
    line-height: 24px;
    text-align: center;
    color: #FFA620;
    cursor: pointer;
}
p{
    margin-bottom: 0;
}
</style>