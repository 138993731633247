import Vue from "vue";
import Router from "vue-router";
import mainPage from "@/views/mainPage.vue"
import searchPage from "@/views/search/searchPage"
import resultPage from "@/views/search/resultPage"
import firstStep from "@/views/step/firstStep"
import secondStep from "@/views/step/secondStep"
import thirdyStep from "@/views/step/thirdyStep"
import lastStep from "@/views/step/lastStep"
import forYou from "@/views/serviceMobile/forYou"
import forCar from "@/views/serviceMobile/forCar"
import mainStep from '@/views/step/main'

Vue.use(Router);

export default new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            component: mainPage
        },
        {
            path: "/search",
            component: searchPage
        },
        {
            path:"/result",
            component: resultPage
        },
        {
            path:"/firstStep",
            component: firstStep
        },
        {
            path:"/secondStep",
            component: secondStep
        },
        {
            path:"/thirdyStep",
            component: thirdyStep
        },
        {
            path:"/lastStep",
            component: lastStep
        },
        {
            path:"/forYou",
            component: forYou
        },
        {
            path:"/forCar",
            component: forCar
        },
        {
            path:"/services",
            component: mainStep
        },

    ] 
})