<template>
        <div class="logoContainer" >
            <img src="@/assets/headerImages/logoPF.svg" class="logoPF">
            <img src="@/assets/headerImages/plusIcon.svg" class="plusIcon">
            <img src="@/assets/headerImages/logoBB.svg" class="logoBB">
            <menuMobile class="menuMobile"></menuMobile>
        </div>              
</template>

<script>
import menuMobile from '@/utils/menuMobile.vue';
export default {
    components:{
        menuMobile
    },
    data(){
        return{

        }
    },
}
</script>

<style scoped>
.logoContainer{
    z-index: 1;
    display: flex;
    flex-direction: row;
    width: 100%;
    background: linear-gradient(75.9deg, #1E59AE 30.53%, #465EFF 94.78%);
    padding-top: 2.5%;
    padding-bottom: 2.5%;
}
.logoPF{
    width:9%;
    max-width: 9%;
    margin-left: 6.5%;
}
.plusIcon{
    padding-left: 1.5%;
    width: 3%;
}
.logoBB{
    padding-left: 1.5%;
    max-width: 18%;
    width: 18%;
}
.linkHome{
    padding-top: 1%;
    display: flex;
    margin-left: 45%;
    z-index: 9999;
    color: white;
    text-decoration: none;
    font-size: max(1.3vw, 13px);
}
@media screen and (max-width:1300px){
    .linkHome{
        margin-left: 35%;
    }
}

@media screen and (max-width:600px){
    .logoPF {
        width: 20%;
        max-width: 20%;
        margin-left: 6.5%;
    }   
    .plusIcon {
        padding-left: 1.5%;
        width: 5%;
    }
    .logoBB {
        padding-left: 1.5%;
        max-width: 32%;
        width: 32%;
    }
    .linkHome{
        margin-left: 20%!important;
        padding-top: 1.9%!important;
        font-size: max(1.3vw, 11px)!important;
    }
    
}
@media screen and (min-width:501px){
    .logoContainer{
        display: none!important;
    }
}
@media screen and (max-width:500px){
    .logoContainer{
        display: block!important;
    }
}
@media screen and (max-width:380px){
   
    .logoPF {
        width: 22%;
        max-width: 22%;
        margin-left: 6.5%;
    }   
    .plusIcon {
        padding-left: 1.5%;
        width: 4%;
    }
    .logoBB {
        padding-left: 1.5%;
        max-width: 34%;
        width: 34%;
    }
    .linkHome{
        margin-left: 7%!important;
        padding-top: 2.5%!important;
        font-size: max(1.3vw, 11px)!important;
    }
}
</style>