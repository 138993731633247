<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    data(){
    return{
      dataAtual : ""
    }
  },
  
}
</script>

<style>
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button { -webkit-appearance: none; margin: 0; }
*{
  
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}


</style>
