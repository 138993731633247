import Vue from "vue";
import Vuex from "vuex";
import { HTTP } from '@/api_system'
Vue.use(Vuex);


export default new Vuex.Store({
    state: {
        service:{
            serviceType: "",
            serviceName: "",
            serviceValue:"",
            nameUser:"",
            atualDate:"",
            emailUser:"",
            telUser:"",
            cpfUser:"",
            endUser:"",
            city:"",
            state:""
        },
        search:{
            id: "",
            cpf:"",
        },
        tokenStore : '',
        itemService: '',
        dataQRCodeStore: '',
        dataCurrentValueService: '',
        filesUpload: [],
        dataFilesRequest: '',
        dataUser: [],
        searchService: ''
        
    },
    mutations:{
        selectedService(state, payload){
            state.service.serviceName = payload.name
            state.service.serviceValue = payload.value
            state.service.serviceType = payload.type
        },
        inputUser(state,payload){
            state.service.nameUser = payload.userName
            state.service.telUser = payload.telUser
            state.service.emailUser = payload.emailUser
        },
        inputUserSS(state, payload){
            state.service.cpfUser = payload.cpfUser
            state.service.endUser = payload.endUser
            state.service.city = payload.city
            state.service.state = payload.state

        },
        dataAtual(state, payload){
            state.service.atualDate = payload.data
        },
        getId(state, payload){
            state.search.id = payload.id
            state.search.cpf = payload.cpf
        },
        setToken(state, payload) {
            state.tokenStore = payload
        },
        setItemService(state, payload) {
            state.itemService = payload
        },
        setQRCodeStore(state, payload) {
            state.dataQRCodeStore = payload
        },
        setDataCurrentValueService(state, payload) {
            state.dataCurrentValueService = payload
        },
        setFilesUpload(state, payload) {
            state.filesUpload = payload
        },
        setDataFilesRequest(state, payload) {
            state.dataFilesRequest = payload
        },
        setDataUser(state, payload) {
            state.dataUser = payload
        },
        setSearchService(state, payload) {
            state.searchService = payload
        },
    },
    actions:{
        getPedidos({getters}, item) {
            const { cpf, orderId } = item
            return new Promise((resolve, reject) => {
                HTTP.get(`/services/app/PixBB/GetOrderBB?cpf=${cpf}&orderId=${orderId}`, {  headers: { Authorization: `${getters.getToken}`} }).then(res => {
                    resolve(res.data)
                }).catch(err => reject(err))
            })    
        },
        getServicesDetails({getters}) {
            const { serviceId } = getters.getDataCurrentValueService
            return new Promise((resolve, reject) => {
                HTTP.get(`/services/app/PixBB/GetServicesDetails?id=${serviceId}`, {  headers: { Authorization: `${getters.getToken}`} }).then(res => {
                    resolve(res.data)
                }).catch(err => reject(err))
            })    
        },
    },
    getters: {
        getToken(state){
            return state.tokenStore
        },
        getService(state){
            return state.service
        },
        getItemService(state){ 
            return state.itemService
        },
        getQRCodeStore(state){ 
            return state.dataQRCodeStore
        },
        getDataCurrentValueService(state){ 
            return state.dataCurrentValueService
        },
        getFilesUpload(state){ 
            return state.filesUpload
        },
        getDataFilesRequest(state){ 
            return state.dataFilesRequest
        },
        getDataUser(state){ 
            return state.dataUser
        },
        getSearchService(state){ 
            return state.searchService
        },
    }
})
