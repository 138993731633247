<template>
<div>
  <other-components-header></other-components-header>
  <componets class="mainCp" :is="cp" @cpSearch="cpSearch" />
  <div>
    <footer-in-footer></footer-in-footer>
  </div>
</div>
</template>

<script>
import otherComponentsHeader from '@/components/Header/otherComponentsHeader.vue'
import footerInFooter from '@/components/Footer/footerInFooter.vue';
import {
  HalfCircleSpinner
} from 'epic-spinners'
import {
  mapActions,
  mapMutations
} from 'vuex';
export default {
  components: {
    otherComponentsHeader,
    footerInFooter,
    HalfCircleSpinner,
    search: () => import('@/components/searchResult/search.vue'),
    resultRequest: () => import('@/components/searchResult/resultRequest.vue'),
  },
  data() {
    return {
      userId: "",
      userCpf: "",
      cp: 'search'
    }
  },
  methods: {
    ...mapActions(['getPedidos']),
    ...mapMutations(['setSearchService']),
    cpSearch(value) {
      this.cp = value
    }

  },

}
</script>

<style scoped>
.mainCp {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  justify-content: center;
}

</style>
