var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('whats-app-fixed'),_c('other-components-header'),_c('div',{staticClass:"allContainer"},[_c('div',{staticClass:"titleContainer"},[_c('p',{staticClass:"title"},[_vm._v(_vm._s(_vm.$store.state.service.serviceName))])]),_c('div',{staticClass:"stepContainer"},[_c('router-link',{staticClass:"setaStep",attrs:{"to":"/secondStep"}},[_c('img',{attrs:{"src":require("@/assets/stepsImages/setaStep.svg"),"alt":""}})]),_vm._m(0)],1),_vm._m(1),_vm._m(2),_c('div',{staticClass:"valueContainer"},[_c('p',{staticClass:"valueDesc"},[_vm._v("Valor do Serviço:")]),_c('p',{staticClass:"valueValue"},[_vm._v(" "+_vm._s(_vm.$store.state.service.serviceValue))])]),_c('div',{staticClass:"payContainer"},[_vm._m(3),_c('div',{staticClass:"codeContainer"},[_vm._m(4),_c('div',{staticClass:"pixCode"},[_c('p',{attrs:{"id":"texPixCode"}},[_vm._v(_vm._s(_vm.$store.getters.getQRCodeStore.qrCode))])]),_c('div',{staticClass:"pixCopyText",on:{"click":_vm.copyPixTxt}},[_vm._m(5),_vm._m(6)])])]),_c('div',{staticClass:"buttonContainerSearch"},[_c('router-link',{staticClass:"searchButton",attrs:{"to":"/lastStep"}},[_vm._v("Após o pagamento, clique aqui")])],1)]),_c('div',[_c('footer-in-footer')],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stepTree"},[_c('img',{staticClass:"stepTreeImg",attrs:{"src":require("@/assets/stepsImages/stepTree.svg"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qrCodeTextContainer",attrs:{"id":"desk"}},[_c('p',{staticClass:"qrCodeText"},[_vm._v("Utilize o "),_c('strong',[_vm._v("QRcode")]),_vm._v(" ou "),_c('strong',[_vm._v("copie o código PIX")]),_vm._v(" para efetuar o pagamento.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qrCodeTextContainer",attrs:{"id":"mobile"}},[_c('p',{staticClass:"qrCodeText"},[_c('strong',[_vm._v("Copie o código PIX")]),_vm._v(" para efetuar o pagamento.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qrContainer"},[_c('img',{attrs:{"id":"qrImage","alt":""}}),_c('p',{staticClass:"pixText"},[_vm._v("PIX copia e cola:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pixTextMobile"},[_c('p',[_vm._v("PIX copia e cola:")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('a',[_vm._v("Copiar código Pix")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"orangeVIconContainer"},[_c('img',{staticClass:"orangeVIcon",attrs:{"src":require("@/assets/stepsImages/orangeVIcon.png"),"alt":""}})])
}]

export { render, staticRenderFns }