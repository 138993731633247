<template>
    <div>
        <half-circle-spinner
            :animation-duration="1000"
            :size="60"
            color="#1E59AE"
            v-if="services.length < 1"
            class="mx-auto my-5"
        />
        <div class="buttonsContainer" v-else>
            <div v-on:click="getServiceCurrent(service)" class="dropButton" v-for="(service, index) in services" :key="index">
                <p class="buttonText">{{service.serviceName}}</p>
            <img v-if="`${service.description}` !== 'null' "  src="@/assets/dropdownImages/infoIcon.png" class="toolTip" data-toggle="tooltip" data-placement="top" :title="`${service.description}`">
                <img src="@/assets/dropdownImages/buttonArrow.svg" class="buttonArrow">
            </div>
        </div>
     
    </div>
</template>

<script>
import { HalfCircleSpinner } from 'epic-spinners'
import { mapMutations } from 'vuex'
export default {
    props:["services"],
    components: {
        HalfCircleSpinner,
    },
    data(){
        return{
            selected: "",
            selectedValue:"",
            selectedType:""
        }
    },
    methods:{
        ...mapMutations(['setDataCurrentValueService']),
        selectedService(){
            this.$store.commit("selectedService", {name: this.selected, value:this.selectedValue, type:this.selectedType})
        },  
        getName(x){ // desnecessario
            this.selected = x.serviceName
            this.selectedValue = x.value
            this.selectedType = x.type
            this.selectedService()
            this.$store.commit("setItemService", x)
            this.$router.push('/firstStep')
        },
        getServiceCurrent(value) {
            this.setDataCurrentValueService(value)
            this.$router.push('/services')
        }
    }
}
</script>

<style scoped>

.buttonsContainer{
    padding-top: 3%;
    display: flex;
    align-items: center;
    /* justify-content: center;
    flex-wrap: wrap; */

    
    justify-content: start;
    flex-wrap: wrap;
    max-width: 1591px;
    margin: 0 auto;
}
.buttonArrow{
    margin-left: 5%;
}
.dropButton{
    margin-left: 1%;
    width: 22%;
    border: none;
    height: 53px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: #1E59AE;
    flex: none;
    order: 0;
    flex-grow: 0;
    background: #F7F7FB;
    border-radius: 24px;
    padding-left: 1%;
    margin-bottom: 0!important;
    text-decoration: none;
    margin-top: 20px;
    transition: .1s;
    border: 0px;
}
.buttonText{
    display: flex;
    min-width: 70%;
    max-width: 70%;
    float: left;
    margin-bottom: 0!important;
    /* font-size: max(1.1vw, 12px)!important; */
    font-size: 0.9rem;
    cursor: pointer;
}
.dropButton:hover{
    background: #FFF;
    border: 1px #1E59AE solid;
    color: #0c5dcf;
}
.toolTip{
    max-width: 5%;
    margin-right: 5%;
    
}
@media screen and (max-width:1500px){
    .dropButton{
        font-size: 13px!important;
    }
}
@media screen and (max-width:800px){
    .dropButton{
        font-size: 11px!important;
    }
}
@media screen and (max-width:770px){
    .dropButton{
        width: 30%!important;
    }
    .buttonText{
        min-width: 62%!important;
        max-width: 62%!important;
    }
    .toolTip{
        max-width: 7%!important;
    }
}
/* .buttonsContainer{
    display: none;
} */
</style>