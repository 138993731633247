<template>
<div>
  <whats-app-fixed></whats-app-fixed>
  <other-components-header></other-components-header>
  <dropdown-servicos></dropdown-servicos>
  <div>
    <div class="allContainer">

      <div class="titleContainer">
        <p class="title">{{ service.serviceName }}</p>
      </div>
      <div>
        <p class="description">{{ service.description }}</p>
      </div>
      <div class="stepContainer">
        <!-- <router-link to="/" class="setaStep"><img src="@/assets/stepsImages/setaStep.svg" alt=""></router-link> -->
        <stepper class="stepOne" :valueStep="stepperV" />
      </div>
      <div v-if="firstStep">
        <p class="fillIn">Preencha os campos para iniciarmos o serviço.</p>
      </div>
      <components :is="cp" @stepperValueNext="stepperValueNext" :service="service" />
    </div>

  </div>
  <div>
    <footer-in-footer class="btonPosition"></footer-in-footer>
  </div>
</div>
</template>

<script>
import dropdownServicos from '@/components/Header/dropdownServicos.vue'
import whatsAppFixed from '@/utils/whatsAppFixed.vue';
import otherComponentsHeader from '@/components/Header/otherComponentsHeader.vue';
import footerInFooter from '@/components/Footer/footerInFooter.vue';
import stepper from '@/components/step/stepper.vue'
import {
  mapGetters
} from 'vuex'
export default {
  components: {
    otherComponentsHeader,
    footerInFooter,
    whatsAppFixed,
    dropdownServicos,
    stepper,
    firstStep: () => import('@/components/step/cpFirstStep.vue'),
    secondStep: () => import('@/components/step/cpSecondStep.vue'),
    thirdyStep: () => import('@/components/step/cpThirdyStep.vue'),
    lastStep: () => import('@/components/step/cpLastStep.vue')
  },
  data() {
    return {
      cp: 'firstStep',
      stepperV: 1
    }
  },
  computed: {
    ...mapGetters({
      service: 'getDataCurrentValueService'
    })
  },
  mounted () {
    if(!this.service) {
      this.$router.push('/')
    }
  },
  methods: {
    stepperValueNext(nextComponent) {
      this.stepperV = this.stepperV + 1
      this.cp = nextComponent
    }
  },
}
</script>

<style scoped>
.description {
  width: 95%;
  margin: 0 auto;
  text-align: center;
  font-size: 1rem;
  line-height: 1.6;
  font-weight: 500;
  color: #444243ad;
}

span {
  color: #cc1313;
}

.title {
  margin: 0;
  font-size: 1.2rem;
}

.displayShow {
  display: none;
}

.arrowInButton {
  width: 9px;
}

.titleContainer {
  display: flex;
  justify-content: center;
  background: #F7F7FB;
  border-radius: 24px;
  min-width: 30%;
  padding: 0 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 45px;
  text-align: center;
  color: #465EFF;
  height: auto;
  margin-bottom: 3%;
  margin: 40px auto 20px;

}

.allContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 90vh;
  flex-direction: column;
}

.stepContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2%;
}

.stepOne {
  margin-right: 43%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.setaStep {
  margin-right: 43%;
}

@media (min-width: 768px) {
  .description {
    width: 50%;
  }
}

@media screen and (max-width:800px) {
  .setaStep {
    margin-right: 20%;
  }

  .stepOne {
    margin-right: 20%;
  }
}

@media screen and (min-width:600px) {
  .rowContainer {
    min-width: 612px;
  }
}

@media screen and (max-width:600px) {
  .titleContainer {
    width: 73%;
    margin-bottom: 3%;
  }

  .title {
    font-size: max(1.3vw, 20px);
  }

  .stepOne {
    margin-right: 4%;
    width: 80%;
  }

  .stepOneImg {
    width: 90%;
  }

  .setaStep {
    margin-right: 9%;
  }

  .rowContainer {
    width: 90% !important;
  }

  .searchButton {
    width: 34% !important;
  }

  .buttonContainerSearch {
    margin-bottom: 14% !important;
  }
}

@media screen and (max-width:450px) {
  .titleContainer {
    width: 95%;
  }
}

@media screen and (max-width:420px) {
  .stepOne {
    margin-right: -6%;
    width: 80%;
  }

  .stepOneImg {
    width: 82%;
  }

  .setaStep {
    margin-right: 6%;
  }
}

@media screen and (max-width:335px) {
  .title {
    font-size: max(1.3vw, 16px) !important;
  }
}

#row {
  width: 100%;
  margin-top: 8%;
}

#inputForm {
  background: #FFFFFF;
  opacity: 0.8;
  border: 1px solid #1e59ae;
  border-radius: 20px;
}

label {
  font-style: normal;
  font-weight: 400;
  font-size: max(1vw, 13px);
  line-height: 24px;
  color: #797984;
  margin-top: 10px;
  margin-bottom: 0 !important;
}

.fillIn {
  margin-top: 10%;
  font-style: normal;
  font-weight: 400;
  font-size: max(1.3vw, 23px);
  line-height: 24px;
  text-align: center;
  color: #1E59AE;
}

.searchButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 22px;
  gap: 10px;
  background: #FFA620;
  border-radius: 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 113.2%;
  color: #FFFFFF;
  border: none;
  width: 45%;
  max-height: 49px;
  text-decoration: none;
}

.searchButton:hover {
  background: #f99500;
  transition: .7s;
}

.buttonContainerSearch {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 6%;
}

.rowContainer {
  display: flex !important;
  justify-content: center !important;
}

@media (min-width: 768px) {
  .stepContainer {
    justify-content: initial;
  }

}
</style>
