<template>
<div :class="dataItem.result.parametrizations > 1 ? 'itemcontainer' : 'containerItensGrid'">
  <h1>Serviço</h1>
  <div class="containerField">
    <div class="div" v-for="item in dataItem.result.parametrizations" :key="item.id">
      <components class="mt-3" :is="`Cp${item.fieldType}`" :item='item' />
    </div>
  </div>
  <div class="buttonContainerSearch">
    <button @click="getValues" class="searchButton">
      <half-circle-spinner :animation-duration="1000" :size="30" color="#fff" v-if="load" class="mx-auto my-5" />
      <div v-else>
        Prosseguir
        <img src="@/assets/headerImages/arrowButton.svg" class="arrowInButton" alt="">
      </div>
    </button>
  </div>
</div>
</template>

<script>
import {
  mapActions
} from 'vuex'
import {
  HalfCircleSpinner
} from 'epic-spinners'

// eslint-disable
export default {
  data() {
    return {
      data: [],
      fieldvalues: [],
      sendError: false,
      nameCP: 'service',
      filesItens: [],
    }
  },
  components: {
    // indexdocument: () => ('@/components/service/field/indexdocument.vue'),
    Cptext: () => import('@/components/service/fields/inputText.vue'),
    Cpradio: () => import('@/components/service/fields/inputRadio.vue'),
    Cpfile: () => import('@/components/service/fields/inputFile.vue'),
    Cpdate: () => import('@/components/service/fields/inputDate.vue'),
    HalfCircleSpinner
  },
  props: ['dataItem', 'load'],
  methods: {
    ...mapActions(['getServicesDetails']),
    getValues() {
      const values = [];
      let data = new FormData();
      const doc = document.querySelectorAll(".getValue");
      doc.forEach(({
        value,
        name,
        type,
        required
      }) => {
        if (required && value == "") {

          this.sendError = true;
          setTimeout(() => {
            this.sendError = false;
          }, 1000);
          return;
        }

        if (value == "") {
          return;
        }

        if (type == 'file') {

          // let data = new FormData();
          const file = this.$store.getters.getFilesUpload
          for (let i = 0; i < file.length; i++) {
            data.append(file[i].name, file[i]);
          }

          this.$store.commit('setDataFilesRequest', data);

        } else if (name === 'fieldCPF') {
          values.push(`${name}: ${value.replace(/[^0-9]/g, '')}`);
        } else {
          values.push(`${name}: ${value}`);
        }
      });

      if (this.sendError) {
        this.$toast.error("Preencha todos os campos obrigatorios.");
        return
      }
      this.fieldvalues = values;
      this.$emit('valuesField', values)

    },
    async getServices() {
      try {
        const result = await this.getServicesDetails()
        this.data = (result)
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style scoped>
.itemcontainer {
  max-width: 622px;
}

.containerItensGrid {
  width: 95%;
}

h1 {
  margin-top: 10%;
  font-style: normal;
  font-weight: 400;
  font-size: max(1.3vw, 23px);
  line-height: 24px;
  color: #1E59AE;
}

.containerField {
  max-width: 615px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-auto-rows: auto;
}

.containerField .div label {
  font-style: normal;
  font-weight: 400;
  font-size: max(1vw, 13px);
  line-height: 24px;
  color: #797984;
  margin-top: 10px;
  margin-bottom: 0 !important;
}

.containerField .div {
  margin: 0 2%;
  flex: 1;
}

/* .containerField {
  max-width: 615px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}

.containerField .div {
  width: 45%;
  margin: 0 2%;
  flex: 1;
} */

#inputForm {
  background: #FFFFFF;
  opacity: 0.8;
  border: 1px solid #1e59ae;
  border-radius: 20px;
}

label {
  font-style: normal;
  font-weight: 400;
  font-size: max(1vw, 13px);
  line-height: 24px;
  color: #797984;
  margin-top: 10px;
  margin-bottom: 0 !important;
}

.buttonContainerSearch button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 22px;
  gap: 10px;
  background: #FFA620;
  border-radius: 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 113.2%;
  color: #FFFFFF;
  border: none;
  width: 22%;
  max-height: 49px;
  text-decoration: none;
  width: 225px;
  margin: 55px auto;
}

@media (min-width: 768px) {
  .containerField {
    grid-template-columns: repeat(2, 1fr);
  }

  .containerItensGrid {
    width: 622px;
  }
}
</style>
