<template>
    <div> 
        <capa-header-vue id="capaHeader"></capa-header-vue>
        <mobileHeader class="teste"></mobileHeader>
        <tutorial-header-vue></tutorial-header-vue>
        <type-service-header id="typeService"></type-service-header>
        
    </div>
</template>
<script>
import capaHeaderVue from './capaHeader.vue';
import tutorialHeaderVue from './tutorialHeader.vue';
import mobileHeader from './mobileHeaderHome.vue'
import typeServiceHeader from './typeServiceHeader.vue';

export default {
    components: {
        capaHeaderVue,
        tutorialHeaderVue,
        typeServiceHeader,
        mobileHeader,
    }  ,
    
}
</script>
<style scoped>
@media screen and (min-width:501){
    .teste{
        display: none!important;
    }
}
@media screen and (max-width:500){
    .teste{
        display: block!important;
    }
}
@media screen and (min-width:501){
    #capaHeader{
        display: block!important;
    }
}
@media screen and (max-width:500){
    #capaHeader{
        display: none!important;
    }
}
.teste{
    position: fixed;
    top: 0;
    z-index: 9;
}
</style>