<template>
    <div class="menuIconMobile">
        <img id="buttonMenu" v-on:click="abre()" src="@/assets/allImages/menuIcon.svg" >
        <nav id="navMenu">
            <div class="navHeader">
                <img src="@/assets/onlyMobileImg/mobileLogo.svg" class="logoNav">
                <img v-on:click="fecha()" src="@/assets/onlyMobileImg/closeMenu.svg" class="removeMenu">
            </div>
            <div class="linkContainer">
                <a class="link" v-on:click="fecha()" href="#capaHeader">Home</a>
                <a class="link" v-on:click="fecha()" href="#typeService">Serviços</a>
                <div class="buttonsContainer">
                    <router-link to="/forYou" class="yellowButton">Para Você <img src="@/assets/onlyMobileImg/blueArrow.svg" class="blueArrow"></router-link>
                    <router-link to="/forCar" class="blueButton">Para o seu veículo <img src="@/assets/onlyMobileImg/whiteArrow.svg" class="whiteArrow"></router-link>
                </div>
                <a class="link" v-on:click="fecha()" href="#secondBody">A parceria</a>
                <a class="link" v-on:click="fecha()" href="#formsFooter">Contato</a>
            </div>
            <div class="footerButtons">
                <router-link to="/search" class="followButton">
                    Acompanhar Pedido
                    <img src="@/assets/headerImages/lupa.svg">
                </router-link>
                <button class="buttonSecondBody" v-on:click="fecha()" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    Falar com nosso especialista!
                    <img src="@/assets/bodyImages/WHATSAPP.svg" class="wppIcon">
                </button>
                <modal-wpp-vue></modal-wpp-vue>
            </div>
        </nav>
    </div>
</template>

<script>
    import modalWppVue from './modalWpp.vue'
export default {
    components:{
        modalWppVue
    },
    methods:{
        abre(){
            const menu = document.querySelector('nav')
            menu.classList.add('open')
        },
        fecha(){
            const menu = document.querySelector('nav')
            menu.classList.remove('open')
        }
    },
    mounted(){

    }
}
</script>

<style scoped>
.logoNav{
    width: 69%;
}
.removeMenu{
    margin-left: 12%;
    margin-top: 2%;
}
.navHeader{
    margin-top: 7%;
    margin-left: 5%;
}
nav{
    z-index: 99999999999;
    min-height: 100vh;
    position: fixed;
    right: -100%;
    top: 0;
    bottom: 0;
    width: 100%;
    transition: .2s ease-in-out;
    background-color: #FFF;
}
a{
    margin-bottom: 3%!important;
    text-decoration: none;
}

.open{
    right: 0;
}
.menuIconMobile{
    cursor: pointer;
    display: fixed;
    top: 0;
    z-index: 9;
}
.removeMenu{
    cursor: pointer;
}
.linkContainer{
    margin-top: 7%;
    display: flex;
    margin-left: 5.5%;
    flex-direction: column;
}
.link{
    padding-top: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: max(4.5vw, 19px);
    line-height: 150%;
    color: #797984;
    text-decoration: none;
}
.buttonsContainer{
    display: flex;
    flex-direction: column;
    width: 50%;
}
.yellowButton{
    background: #FDF429;
    border-radius: 24px;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    color: #465EFF;
    display: flex;
    padding-left: 7%;
    align-items: center;
    height: 40px;
    text-decoration: none;
}
.blueArrow{
    margin-left: 10%;
}
.whiteArrow{
    margin-left: 15%;
}
.blueButton{
    background: #465EFF;
    border-radius: 24px;
    border: none;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 150%;
    color: #FFFF;
    display: flex;
    padding-left: 7%;
    align-items: center;
    height: 40px;
    text-decoration: none;
    margin-top: 3px;
}
.footerButtons{
    display: flex;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    margin-top: 42%;
}
.followButton{
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px 22px;
    gap: 2px;
    background: #71A1FF;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: max(1.2vw, 13px);
    line-height: 113.2%;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    text-decoration: none;
    height: 48px;
    font-size: max(4.2vw, 18px);
}
.followButton:hover{
    background: #225bce;
    transition: 0.7s;
}
.buttonSecondBody{
    width: 80%;
    height: 3.5rem;
    background: #FFF;
    border-radius: 30px;
    font-weight: 600;
    font-size: max(4.2vw, 18px);
    color: #225bce;
    gap: 2px;
    border: 1px #225bce solid;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 3%;
    height: 48px;
}
.buttonSecondBody:hover{
    background: #00BB2D;
    border: none;
    color: #FFF;
    transition: 0.6s;
}
.wppIcon{
    padding-left: 1.5%;
}
@media screen and (max-width:499px){
    .buttonsContainer{
        width: 45%!important;
    }
    .menuIconMobile{
        width: 100%;
        position: absolute;

    }
    #buttonMenu{
        margin-left: 85%;
        margin-top: 3.5%;
    }
}
@media screen and (min-width:500px){
    .menuIconMobile{
        display: none;
    }
}
@media screen and (max-width:475px){
    .buttonsContainer{
        width: 65%!important;
    }
}
@media screen and (max-width:339px){
    .whiteArrow{
        margin-left: 5%!important;
    }
    .buttonsContainer{
        width: 68%!important;
    }
}
</style>