<template>
    <div>
        <section-one-body></section-one-body>
        <section-two-body id="secondBody"></section-two-body>
    </div>
</template>

<script>
import sectionOneBody from './sectionOneBody.vue';
import sectionTwoBody from './sectionTwoBody.vue';
export default {
    components:{
        sectionOneBody,
        sectionTwoBody
    }
}
</script>

<style scoped>

</style>