<template>
     <div>   
        <div class="secondBody">
            <div class="secondBodyContainer">
                <div class="peopleTextContainer">
                    <p class="friendly">Uma parceria de Sucesso</p>
                    <p class="together">Ponto Fácil e<br>Banco do Brasil</p>
                    <img src="@/assets/bodyImages/secondBodyText.svg" class="secondBodyText">
                    <img src="@/assets/bodyImages/secondBodyTxtMobile.svg" class="secondBodyTextMobile">
                    <div class="secondBodyContainerTwoMobile">
                        <img src="@/assets/bodyImages/backgroundPFMobile.svg" class="pfMobile">
                    </div>
                </div>
                <div class="SecondButtonBodyContainer" >
                    <button class="buttonFirtBody" id="typeTwo" data-bs-toggle="modal" data-bs-target="#wppModal">
                        Encontre a Ponto Fácil mais perto de você!                        
                    </button>
                    <button class="buttonSecondBody" id="typeTree" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Fale Conosco
                        <img src="@/assets/bodyImages/WHATSAPP.svg" class="wppIcon">
                    </button>
                    <modal-wpp-vue></modal-wpp-vue>
                    <modal-reg-vue></modal-reg-vue>
                </div>
            </div>
            <div class="secondBodyContainerTwo">
                
            </div>
            
        </div>   
    </div>
</template>

<script>
    import modalWppVue from '@/utils/modalWpp.vue';
    import modalRegVue from '@/utils/modalReg.vue';
export default {
    components:{
        modalWppVue,
        modalRegVue
    }
}
</script>

<style scoped>
    @media screen and (min-width:2400px){
        .secondBodyContainerTwo{
            margin-left: 27%!important;
        }
    }
    @media screen and (min-width:2000px){
        .secondBodyContainerTwo{
            margin-left: 25%!important;
        }
    }
    @media screen and (max-width:1600px){
        .buttonFirtBody{
            height: 2.5rem!important;
        }
        .buttonSecondBody{
            height: 2.5rem!important;
        }

    }
    @media screen and (max-width:1000px){
        .secondBodyContainerTwo{
            margin-left: 12%!important;
        }
    }
    @media screen and (max-width:850px){
        .secondBodyContainerTwo{
            width: 73%!important;
        }
    }
    @media screen and (max-width:830px){
        .buttonFirtBody{
            font-size: max(0.9vw, 12px)!important;
        }
    }
    @media screen and (max-width:600px){
        .peopleTextContainer{
            display: flex;
            flex-direction: column;
        }
        .secondBodyText{
            display: none;
        }
        .secondBodyContainerTwo{
            display: none;
        }
        .friendly{  
            font-size: max(1.3vw, 22px);
            max-width: 100%!important;
        }
        .together {
            font-size: max(2vw, 29px);
            max-width: 100%!important;
        }
    }
    @media screen and (min-width:601px){
        .secondBodyTextMobile{
            display: none!important;
        }
        .secondBodyContainerTwoMobile{
            display: none!important;
        }
    }
    @media screen and (max-width:450px){
        .buttonFirtBody{
            width: 114%!important;
        }
        .buttonSecondBody{
            width: 114%!important;
        }
    }
    @media screen and (max-width:420px){
        .buttonFirtBody{
            width: 105%!important;
        }
        .buttonSecondBody{
            width: 105%!important;
        }
    }
    @media screen and (max-width:400px){
        .buttonFirtBody{
            width: 100%!important;
        }
        .buttonSecondBody{
            width: 100%!important;
        }
        .secondBodyTextMobile{
            width: 70%;
        }
        .SecondButtonBodyContainer[data-v-77802276] {
            max-width: 90%;
        }
    }
    @media screen and (max-width:350px){
        .pfMobile{
            width: 70%;
        }
    }
.backgroundPeople{
    width: 100%;
}
.pfMobile{
    margin-top: 10%;
}
.secondBody{
    margin-top: 5.5%;
    display: flex;
    margin-left: 7.5%;
    display: flex;
    flex-direction: row;
    max-width: 100%;
    padding-bottom: 5%;
}
.secondBodyContainerTwo{
    background-image: url('@/assets/bodyImages/backgroundPF.png');
    width: 47%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    margin-left: 19%;
    padding-top: 58%;
}
.secondBodyTextMobile{
    margin-top: 5%;
}
.SecondButtonBodyContainer{
    display: flex;
    flex-direction: column;
    max-width: 100%;
    margin-bottom: 5%;
}
.buttonFirtBody{
    width: 100%;
    height: 3.5rem;
    background: #FFFF;
    border-radius: 30px;
    font-weight: 600;
    font-size: max(0.9vw, 14px);
    color: #1E59AE;
    gap: 2px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 10%;
}
.buttonFirtBody:hover{
     background:  #FFA620;
     color: #FFFF;
     transition: 0.6s;
}
.buttonSecondBody{
    width: 100%;
    height: 3.5rem;
    background: #1E59AE;
    border-radius: 30px;
    font-weight: 600;
    font-size: max(0.9vw, 14px);
    color: #FFF;
    gap: 2px;
    border: 1px #FFF solid;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 3%;
}
.buttonSecondBody:hover{
    background: #00BB2D;
    border: none;
    transition: 0.6s;
}
#typeTwo{
    background: #FFA620;
    color: #FFF;
    width: 76.8%;
}
#typeTwo:hover{
    background: #ea8c00;
}
#typeTree{
    background: #FFF;
    color: #1E59AE;
    border: 1px #1E59AE solid;
    width: 76.8%;
}
#typeTree:hover{
    background: #00BB2D;
    border: none;
    transition: 0.6s;
    color: #FFF;
}
.wppIcon{
    padding-left: 1.5%;
}
.friendly{
    font-style: normal;
    font-weight: 400;
    font-size: max(1.3vw, 20px);
    line-height: 39px;
    color: #FFA620;
    max-width: 60%;
}
.together{
    font-style: normal;
    font-weight: 700;
    font-size:max(2vw, 25px);
    line-height: 130%;
    color: #1E59AE;
    margin-bottom: 5%;
    max-width: 60%;
}

.secondBodyText{
    max-width: 100%;
    
}
</style>