<template>
    <div>
        <footer>
            <div>
                <div class="footerContainer">
                    <div class="logoContainer">
                        <img src="@/assets/headerImages/logoPF.svg" class="logoPF">
                        <img src="@/assets/headerImages/plusIcon.svg" class="plusIcon">
                        <img src="@/assets/headerImages/logoBB.svg" class="logoBB">
                    </div> 
                    <div class="dowFooter">
                        <div class="direitosContainer">
                            <p class="direitosFrase">2022 - Ponto Fácil © Todos os direitos reservados</p>
                        </div>
                        <div class="goToTop">
                                <img @click="goToTop" src="@/assets/footerImages/goToTopImage.svg">
                        </div>
                    </div> 
                </div>
            </div>
        </footer>
    </div>
</template>

<script>

export default {
    methods:{
        goToTop(){
            var btn = document.querySelector(".goToTop");
            btn.addEventListener("click", function() {
                window.scrollTo(0, 0);
            });
        }
    }
}
</script>

<style scoped>
@media screen and (max-width:600px){
    .logoPF{
        width: 28%;
    }
    .logoBB{
        width: 46%;
    }
    .plusIcon{
        width: 5%;
    }
    .goToTop img{
        width: 80%;
    }
    .direitosContainer{
        margin-left: 13%;
    }
}
.footerContainer{
    background-color: #1E59AE;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 2%;
    margin-top: 2%;
    width: 100%;
    flex-direction: column;
}
.logoContainer{
    width: 90%;
    max-width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.logoPF{
    padding-right: 1%;
}
.plusIcon{
    padding-right: 1%;
}
.direitosContainer{
    margin-top: 1%;
    max-width: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid #465EFF;
    padding-top: 1%;
}
.direitosFrase{
    font-style: normal;
    font-weight: 400;
    font-size: max(1vw, 10px);
    line-height: 21px;
    color: #5B9CCF;
}
.dowFooter{
    display: flex;
    flex-direction: row;
    max-width: 80%;
    width: 80%;
}
.goToTop{
    display: flex;
    float: right;
    max-height: 35px;
    padding-left: 1%;
    margin-bottom: 1%;
    cursor: pointer;
}
</style>