<template>
    <div>
        <div class="modal fade modal-lg" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered ">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title text-center" id="exampleModalLabel">Preencha os campos abaixo para iniciar uma conversa no whatsapp</h5>
                <button @click="clearInput" type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <div>
                  <div class="row" id="row">
                    <div class="col-9 p-0" >
                        <label class="mb-1 label_default"
                        >Nome</label
                        >
                        <input id="inputForm"
                        placeholder="Nome"
                        type="text"
                        class="input_default form-control"
                        v-model="userName"
                        @input="valiName"
                        />
                        <span class="spanName">Insira um Nome válido</span>
                    </div>
                    <div class="col-9 p-0" >
                        <label class="mb-1 label_default"
                        >E-mail</label
                        >
                        <input id="inputForm"
                        placeholder="Seu e-mail"
                        type="email"
                        class="input_default form-control"
                        v-model="userEmail"
                        @input="valiMail"
                        />
                        <span id="spanMail" class="spanMail">Insira um E-mail válido</span>
                    </div>
                </div>
                <div class="row" id="rowTwo">
                    <div class="col-2 p-0" id="divFormTwoWpp">
                        <label class="mb-1 label_default"
                            >Estado</label
                        >
                        <select id="inputForm"
                            placeholder="Selecione"
                            type="text"
                            class="input_default form-control"
                            v-model="state"
                            @change="valiState"
                            >
                            <option value="" disabled selected>Selecione</option>
                            <option v-for="state in uf" :key="state.ufCity" :value="state.ufCity">{{state.ufCity}}</option>
                        </select>
                        <span class="spanState">Insira o Estado</span>
                    </div>
                    <div class="col-6 p-0" id="divForm">
                        <label class="mb-1 label_default"
                        >Cidade</label
                        >
                        <select id="inputForm"
                            placeholder="Selecione"
                            type="text"
                            class="input_default form-control"
                            v-model="userCity"
                            @change="valiCity"
                            >
                            <option value="" disabled selected>Selecione</option>
                            <option v-for="state in optionCity" :key="state.nameCity" :value="state.nameCity">{{state.nameCity}}</option>
                        </select>
                        <span class="spanCity">Insira a Cidade</span>
                    </div>  
                </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary" id="buttonSend" v-on:click="apiWpp">
                  Iniciar Conversa
                  <img src="@/assets/bodyImages/WHATSAPP.svg" class="wppIcon">
                </button>
                <button @click="clearInput" type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="buttonClose" >Fechar</button>
              </div>
            </div>
          </div>
        </div>   
    </div>
</template>
<script>
import { HTTP } from "@/api_system"
export default { 
  props:["valida"],
  data(){
    return{
      userName:"",
      userEmail:"",
      userCity:"",
      uf:[],
      state:"",
      optionCity:[],
      routerPath:""
    }
  },
  methods:{
    apiWpp(){
      if(!this.state && !this.userCity && !this.userEmail && !this.userName){
        let span = document.querySelector('.spanState')
        span.style.display = 'block'
        let spanCity = document.querySelector('.spanCity')
        spanCity.style.display = 'block'
        let spanName = document.querySelector('.spanName')
        spanName.style.display = 'block'
        let spanMail = document.querySelector('.spanMail')
        spanMail.style.display = 'block'
      }else if(!this.state && !this.userCity && !this.userEmail && this.userName){
        let span = document.querySelector('.spanState')
        span.style.display = 'block'
        let spanCity = document.querySelector('.spanCity')
        spanCity.style.display = 'block'
        let spanMail = document.querySelector('.spanMail')
        spanMail.style.display = 'block'
      }else if(!this.state && !this.userCity && this.userEmail && !this.userName){
        let span = document.querySelector('.spanState')
        span.style.display = 'block'
        let spanCity = document.querySelector('.spanCity')
        spanCity.style.display = 'block'
        let spanName = document.querySelector('.spanName')
        spanName.style.display = 'block'
      }else if(this.state && !this.userCity && !this.userEmail && !this.userName){
        let spanCity = document.querySelector('.spanCity')
        spanCity.style.display = 'block'
        let spanName = document.querySelector('.spanName')
        spanName.style.display = 'block'
        let spanMail = document.querySelector('.spanMail')
        spanMail.style.display = 'block'
      }else if(!this.state && !this.userCity && this.userEmail && this.userName){
        let span = document.querySelector('.spanState')
        span.style.display = 'block'
        let spanCity = document.querySelector('.spanCity')
        spanCity.style.display = 'block'
      }else if(this.state && this.userCity && !this.userEmail && !this.userName){
        let spanName = document.querySelector('.spanName')
        spanName.style.display = 'block'
        let spanMail = document.querySelector('.spanMail')
        spanMail.style.display = 'block'
      }else if(this.state && !this.userCity && !this.userEmail && this.userName){
        let spanCity = document.querySelector('.spanCity')
        spanCity.style.display = 'block'
        let spanMail = document.querySelector('.spanMail')
        spanMail.style.display = 'block'
      }else if(this.state && !this.userCity && this.userEmail && !this.userName){
        let spanName = document.querySelector('.spanName')
        spanName.style.display = 'block'
        let spanCity = document.querySelector('.spanCity')
        spanCity.style.display = 'block'
      }else if (this.userName.length < 3 ){
        let span = document.querySelector('.spanRequiredName')
        span.style.display = 'block'
      }else if(this.state && this.userCity && this.userEmail && !this.userName){
        let spanName = document.querySelector('.spanName')
        spanName.style.display = 'block'
      }else if(this.state && this.userCity && !this.userEmail && this.userName){
        let spanMail = document.querySelector('.spanMail')
        spanMail.style.display = 'block'
      }else if(!this.state && this.userCity && this.userEmail && this.userName){
        let span = document.querySelector('.spanState')
        span.style.display = 'block'
      }else if(this.$route.path === ('/search') ||  this.$route.path === ('/result')){
        let email = this.userEmail;
        var pattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;        
        if(email.match(pattern)){
          let span = document.querySelector('.spanMail')
          span.style.display = 'none'
          // window.location.href = `https://api.whatsapp.com/send?phone=5519971702568&text=Ol%C3%A1%20meu%20nome%20%C3%A9%20${this.userName}%20e%20meu%20email%20${this.userEmail}%20sou%20da%20cidade%20de%20${this.userCity}%20${this.state}`
          window.location.href = `https://api.whatsapp.com/send?phone=5519971702568&text=Gostaria+de+tirar+d%C3%BAvidas+sobre+a+parceria+Ponto+F%C3%A1cil+%2B+Banco+do+Brasil`
        }else if(email.length === 0){
          let span = document.querySelector('.spanMail')
          span.style.display = 'none'
        }else{
          let span = document.querySelector('.spanMail')
          span.style.display = 'block'
        }
      }else{
        let email = this.userEmail; 
        let pattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;       
        if(email.match(pattern)){
          let span = document.querySelector('.spanMail')
          span.style.display = 'none'
          // window.location.href = `https://api.whatsapp.com/send?phone=551132300318&text=Ol%C3%A1%20meu%20nome%20%C3%A9%20${this.userName}%20e%20meu%20email%20${this.userEmail}%20sou%20da%20cidade%20de%20${this.userCity}%20${this.state}`
          window.location.href = `https://api.whatsapp.com/send?phone=551132300318&text=Gostaria+de+tirar+d%C3%BAvidas+sobre+a+parceria+Ponto+F%C3%A1cil+%2B+Banco+do+Brasil`
        }else if(email.length === 0){
          let span = document.querySelector('.spanMail')
          span.style.display = 'none'
        }else{
          let span = document.querySelector('.spanMail')
          span.style.display = 'block'
        }
      }
    },
    getUf(){
            this.routerPath = this.$route.path
            HTTP.get("services/app/City/GetByUF")
            .then(response => {
                this.uf = response.data.result;
            })
            .catch(error => {
                console.log(error);
            });
        },
        getCity(){
            HTTP.get(`services/app/City/GetByUF?nameUf=${this.state}`)
            .then(response => {
                this.optionCity = response.data.result;
            })
            .catch(error => {
                console.log(error);
            });
        },
        valiMail(k){
            let email = this.userEmail;
            let pattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
            
            if(email.match(pattern)){
                k.target.style.border = '1px solid #5B9CCF'
                let span = document.querySelector('.spanMail')
                span.style.display = 'none'
            }else if(email.length === 0){
                k.target.style.border = '1px solid #5B9CCF'
                let span = document.querySelector('.spanMail')
                span.style.display = 'none'
            }else{
                k.target.style.border = '2px solid #cc1313'
                let span = document.querySelector('.spanMail')
                span.style.display = 'block'
            }
        },
        valiName(x){
            if(this.userName.length > 0 && this.userName.length < 3){
                x.target.style.border = '2px solid #cc1313'
                let span = document.querySelector('.spanName')
                span.style.display = 'block'
            }else if(this.userName.length === 0){
                x.target.style.border = '1px solid #5B9CCF'
                let span = document.querySelector('.spanName')
                span.style.display = 'none'
            }
            else{
                x.target.style.border = '1px solid #5B9CCF'
                let span = document.querySelector('.spanName')
                span.style.display = 'none'
            }
        },
        valiState(z){
            if(this.state !== ""){
                z.target.style.border = '1px solid #5B9CCF'
                let span = document.querySelector('.spanState')
                span.style.display = 'none'
            }
        },
        valiCity(h){
            if(this.userCity !== ""){
                h.target.style.border = '1px solid #5B9CCF'
                let span = document.querySelector('.spanCity')
                span.style.display = 'none'
            }
        },
        clearInput(){
          this.userCity = ""
          this.userEmail = ""
          this.userName = ""
          this.state = ""
          let span = document.querySelector('.spanState')
          span.style.display = 'none'
          let spanCity = document.querySelector('.spanCity')
          spanCity.style.display = 'none'
          let spanName = document.querySelector('.spanName')
          spanName.style.display = 'none'
          let spanMail = document.querySelector('.spanMail')
          spanMail.style.display = 'none'
        }
        
    },
    watch:{
        state(){
            if(this.state){
                this.getCity()
            }
        },
        
        
    },
    mounted(){
        this.getUf()
    }
}
</script>
<style scoped>
span{
    display: none;
    color: #cc1313;
    padding-top: 5px;
    padding-left: 5px;
}
.modal-title{
  color: #000000!important
}
.btn-close{
  background: url('@/assets/allImages/closeOrange.png');
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
}
label{
  color: #727070!important
}
</style>