<template>
    <header>
        <div class="headerContainer">
            <img v-on:click="backToMain()" src="@/assets/onlyMobileImg/closeMenuCar.svg" class="closePage">
            <img src="@/assets/onlyMobileImg/forCarHeader.svg" class="forCarImg">
        </div>
        <div class="searchContainer">
            <input 
                id="inputForm"
                placeholder="Buscar serviço"
                type="text"
                class="input_default form-control"
                @keyup="searchFilter"
            />
            
        </div>
        <div class="rowContainer">
            <div v-on:click="getServiceCurrent(service)" v-for="(service, index) in services" :key="index" class="serviceContainer">
                <p class="titleService">{{service.serviceName}}</p>
                <img class="blueArrow" src="@/assets/onlyMobileImg/blueArrow.svg">
            </div>
        </div>
    </header>
</template>

<script>
// eslint-disable-next-line
import { HTTP } from "@/api_system"
import { mapMutations } from "vuex";
export default {
    data() {
        return {
            services:[],
            servicesOrigin: [],
            selected: "",
            selectedValue:""
        };
    },
    methods:{ 
        ...mapMutations(['setDataCurrentValueService']),
        selectedService(){
            this.$store.commit("selectedService", {name: this.selected, value:this.selectedValue})
        },  
        backToMain(){
            if(this.$route.path !== this.$router.push) {
                this.$router.push("/")
            }
        },
        getServiceCurrent(value) {
            this.setDataCurrentValueService(value)
            this.$router.push('/services')
        },
        backToService(x){
            if(this.$route.path !== this.$router.push) {
                this.selected = x.serviceName
                this.selectedValue = x.value
                this.selectedService()
                this.$router.push("/firstStep")
            }
        },
        getServices(){
        HTTP.get("services/app/PixBB/GetServices", {
            headers: {
                    Authorization: this.token
                }
            })
            .then(response => {
                this.services = response.data.result.servicesForVehicle;
                this.servicesOrigin = response.data.result.servicesForVehicle;  
            })
            .catch(error => {
                console.log(error);
            });
        },
        getToken(){
            HTTP.post("services/app/PixBB/OAuthGenerateToken")
            .then(response => {
                let result
                result = response.data.result;
                this.token = result.access_token
                this.getServices()
            })
            .catch(error => {
                console.log(error);
            });
        },
        searchFilter({target}) {
            const filter = this.servicesOrigin.filter(({serviceName}) => {
                return serviceName.toUpperCase().includes(target.value.toUpperCase())
            })
            this.services = filter
        }  
    },
    mounted(){
        this.getToken()
    }
}
</script>

<style scoped>

.rowContainer{
    margin-top: 4%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.serviceContainer{
    display: flex;
    align-items: center;
    flex-direction: row;
    background: #F7F7FB;
    border-radius: 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 140%;
    color: #1E59AE;
    order: 0;
    height: 58px;
    margin-top: 6px;
    width: 90%;
    cursor: pointer;
}
p{
    padding-left: 6%;
    margin-bottom: 0!important;
    min-width: 92%;
    max-width: 92%;
}
.closePage{
    position: absolute;
    z-index: 9;
    margin-left: 85%;
    margin-top: 19%;
    cursor: pointer;   
}
.headerContainer{
    width: 100%;
}
.forCarImg{
    width: 100%;
    position: relative;
}
.searchContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}
#inputForm{
    width: 90%!important;
    height: 48px!important;
}
</style>

