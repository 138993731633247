<template>
    <div class="firstBody">
            <div class="firstBodyContainer">
                <div class="peopleTextContainer">
                    <img src="@/assets/bodyImages/peopleText.svg" width="1200" class="peopleText">
                </div>
                <div class="peopleTextContainerMobile">
                    <p class="weGoTxt">Nós vamos além!</p>
                    <img src="@/assets/bodyImages/bigText.svg" class="bigTxt">
                    <img src="@/assets/bodyImages/smallText.svg" class="smallTxt">
                </div>
                <div class="buttonFirtsBodyContainer">
                    <button class="buttonFirtBody" data-bs-toggle="modal" data-bs-target="#wppModal">
                        Encontre a Ponto Fácil mais perto de você!                        
                    </button>
                    <button class="buttonSecondBody" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        Fale Conosco
                        <img src="@/assets/bodyImages/WHATSAPP.svg" class="wppIcon">
                    </button>
                    <modal-wpp-vue></modal-wpp-vue>
                    <modal-reg-vue></modal-reg-vue>
                </div>
            </div>
            <div class="backContainer">
                
            </div>  
            <div class="backContainerMobile">
                <img src="@/assets/bodyImages/backgroundPeopleMobile.png" class="backMobile">
            </div>       
    </div>
</template>

<script>
    import modalWppVue from '@/utils/modalWpp.vue';
    import modalRegVue from '@/utils/modalReg.vue';
export default {
    components:{
        modalWppVue,
        modalRegVue
    }
}
</script>

<style scoped>
@media screen and (max-width:830px){
    .buttonFirtBody{
        font-size: max(0.9vw, 12px)!important;
    }
}
@media screen and (max-width:600px){
    .backContainer {
        width: 48%!important;
    }
    .firstBodyContainer {
        display: flex!important;
        margin-top: 5%!important;
        margin-left: -4.5%!important;
        max-width: 58%!important;
        flex-direction: column!important;
        padding-bottom: 5%!important;
    }
    .peopleText {
        max-width: 75%!important;
        margin-left: 25%!important;
    }
}
@media screen and (max-width:500px){
    .backContainer{
        display: none!important;
    }
    .peopleTextContainer{
        display: none!important;
    }
    .firstBodyContainer {
        position: absolute;
        z-index: 1;
        min-width: 100%;
        display: flex!important;
        align-items: center;
    }
    .buttonFirtsBodyContainer {
        margin-left: 0%!important; 
        display: flex!important;
        flex-direction: column!important;
        min-width: 83%!important;
    }
}
@media screen and (min-width:501px){
    .backContainerMobile{
        display: none!important;
    }
    .peopleTextContainerMobile{
        display: none!important;
    }
}
@media screen and (max-width:281px){
   .bigTxt{
        width:80%;
   }
   .smallTxt{
        width: 90%;
   }
}
.peopleTextContainerMobile{
    margin-left: 10%;
}
.smallTxt{
    padding-top: 5%;
}
.weGoTxt{
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #82D2FF;
    padding-top: 30%;
}
.firstBody{
    margin-top: 5.5%;
    display: flex;
    justify-content: space-evenly;
    background:linear-gradient( #1E59AE 100%, #1E59AE 50%);
    background-size: 100%; 
    background-position: center;
}
.firstBodyContainer{
    display: flex;
    margin-top: 5%;
    margin-left: -4.5%;
    max-width: 45%; 
    flex-direction: column;
    padding-bottom: 5%;
}
.peopleText{
    max-width: 65%;
    margin-left: 25%;
}
.buttonFirtsBodyContainer{
    margin-left: 25%;
    display: flex;
    flex-direction: column;
    max-width: 65%;
    margin-top: 10%;
}
@media screen and (max-width:1600px){
    .buttonFirtBody{
        height: 2.5rem!important;
    }
    .buttonSecondBody{
        height: 2.5rem!important;
    }

}
.buttonFirtBody{
    width: 100%;
    height: 3.5rem;
    background: #FFFF;
    border-radius: 30px;
    font-weight: 600;
    font-size: max(0.9vw, 14px);
    color: #1E59AE;
    gap: 2px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 10%;
}
.buttonFirtBody:hover{
     background:  #FFA620;
     color: #FFFF;
     transition: 0.6s;
}
.buttonSecondBody{
    width: 100%;
    height: 3.5rem;
    background: #1E59AE;
    border-radius: 30px;
    font-weight: 600;
    font-size: max(0.9vw, 14px);
    color: #FFF;
    gap: 2px;
    border: 1px #FFF solid;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 3%;
}
.buttonSecondBody:hover{
    background: #00BB2D;
    border: none;
    transition: 0.6s;
}
.wppIcon{
    padding-left: 1.5%;
}

.backContainer{
    width: 60%;
    background-image: url('@/assets/bodyImages/backgroundPeople.png');
    background-position: center;
    background-size: cover;
}
.backContainerMobile{
   position: relative;
}
.backContainerMobile{
    width: 100%;
    max-height: 684px;
}
.backMobile{
    width: 100%;
    max-height: 684px;
}
</style>