<template>
    <div>
        <div class="modal fade modal-lg" id="wppModal" tabindex="-1" aria-labelledby="wppModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-dialog-centered ">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="wppModalLabel">Preencha os campos abaixo para localizar uma Ponto Fácil perto de você!</h5>
                <h5 class="modal-title" id="wppModalLabelTwo">Encontre uma Ponto Fácil perto de você!</h5>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" v-on:click="closeResultModal()"></button>
              </div>
              <div class="modal-body">
                <div id="bodySearch">
                  <div class="row" id="row">
                    <div class="col-9 p-0" >
                        <label class="mb-1 label_default"
                        >Estado</label
                        >
                        <select id="inputForm"
                            placeholder="Selecione"
                            type="text"
                            class="input_default form-control"
                            v-model="stateValue"
                            @change="stateLive"
                            >
                            <option value="" disabled selected>Selecione</option>
                            <option value="Todos">Todos</option>
                            <option v-for="state in cityState" :key="state.uf" :value="state.uf">{{state.uf}}</option>
                        </select>
                        <span class="spanStateReg">Insira o Estado</span>
                    </div>
                    <div class="col-9 p-0" >
                        <label class="mb-1 label_default"
                        >Cidade</label
                        >
                        <select id="inputForm"
                            placeholder="Selecione"
                            type="text"
                            class="input_default form-control"
                            v-model="citieValue"
                            @change="valiCity"
                            >
                            <option value="" disabled selected>Selecione</option>
                            <option value="Todos">Todos</option>
                            <option v-for="city in citie" :key="city.nameCity" :value="city.nameCity">{{city.nameCity}}</option>
                        </select>
                        <span class="spanCityReg">Insira a Cidade</span>
                    </div>
                  </div>
                </div>
                <div id="bodyResult">
                <div class="bodyResultContainer"
                  v-for="(item, index) in result"
                  :key="index"
                  
                > 
                  <div class="bordering" :id="'bordering' + index"
                  >
                    <strong  class="color-blue" id="titleCity">{{ item.city}}</strong
                    ><br />
                    <small class="color-blue">{{ item.email}}</small>
                    <div class="a-address">
                      <a :href="`//www.google.com/maps/search/?api=1&query=${item.address}`" target="_blank" rel="noopener noreferrer"
                        ><p>{{ item.address }}</p></a
                      >
                      <p>{{ item.city }}/{{ item.state }}</p>
                      <p>{{ item.cellphone }}</p>
                      <p>{{ item.socialMedial }}</p>
                    </div>
                  </div>
                </div>
                </div>
              <div class="modal-footer">
                <button v-on:click="openResult()" type="button" class="btn btn-primary" id="buttonSendSearch">
                  Pesquisar
                </button>
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" id="buttonClose" v-on:click="closeResultModal()">Fechar</button>
              </div>
            </div>
          </div>
        </div>   
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/api_system"
export default { 
  data(){
    return{
      citieValue:"",
      stateValue:"",
      tenantCards: [],
      citie:[],
      cityState:[],
      result:[],
      container:[],
      allCity:[],
    }
  },
  methods:{
    stateLive(){
        if(this.stateValue === "Todos"){
          this.citie = [];
          this.allCity = [];
          this.cityState.forEach(item => {
            this.container = item.cities
            this.container.forEach(cityItem =>{
              this.allCity.push(cityItem)
            })
            this.citie = this.allCity
          })
        }
        else{
          const compar = this.cityState.filter(item => this.stateValue == item.uf)
          this.citie = compar[0].cities
        }
             
    },
    abre(){
      const title = document.getElementById('wppModalLabelTwo');
      const result = document.getElementById('bodyResult');
      result.classList.add('open');
      title.classList.add('open');
    },
    fecha(){
      const title = document.getElementById('wppModalLabel');
      title.classList.add('open');
      const titleTwo = document.getElementById('wppModalLabelTwo');
      titleTwo.classList.add('close');
      const search = document.getElementById('bodySearch');
      search.classList.add('open');
      const result = document.getElementById('bodyResult');
      result.classList.add('close');
      
    },
    getNearby(){
      this.abre()
      return HTTP.get('services/app/Tenant/GetAllTenantCards')
      .then((res) => {
        console.log(res)
        let getContainer = res.data.result;
        this.cityState = getContainer.cityStates;
        this.tenantCards = getContainer.tenantCards ;
      })
    },
    openResult(y){
      y = this.stateValue;
      if(!this.stateValue.length && !this.citieValue.length){
        let span = document.querySelector('.spanStateReg')
        span.style.display = 'block'
        let spanCity = document.querySelector('.spanCityReg')
        spanCity.style.display = 'block'
      }else if(this.stateValue.length && !this.citieValue.length){
        let spanCity = document.querySelector('.spanCityReg')
        spanCity.style.display = 'block'
      }else if(y === "Todos" && this.citieValue === "Todos"){
        y = this.citieValue;
        const comparResult = this.tenantCards;
        this.result = comparResult;
        this.fecha();
        this.filterCity;
      }else if(y !== "Todos" && this.citieValue === "Todos"){
        const comparResult = this.tenantCards.filter(item => y == item.state);
        this.result = comparResult;
        this.fecha();
        this.filterCity;
      }else if(y === "Todos" && this.citieValue !== "Todos"){
        y = this.citieValue;
        const comparResult = this.tenantCards.filter(item => y == item.city);
        this.result = comparResult;
        this.fecha();
        this.filterCity;
      }else{
        y = this.citieValue;
        const comparResult = this.tenantCards.filter(item => y == item.city);
        this.result = comparResult;
        this.fecha();
        this.filterCity;
        }
    },
    closeResultModal(){
      this.citieValue = "";
      this.stateValue = "";
      this.citie = [];
      this.allCity = [];
      const title = document.getElementById('wppModalLabel');
      title.classList.remove('open');
      const titleTwo = document.getElementById('wppModalLabelTwo');
      titleTwo.classList.remove('close');
      const search = document.getElementById('bodySearch');
      search.classList.remove('open');
      const result = document.getElementById('bodyResult');
      result.classList.remove('close');
      let span = document.querySelector('.spanStateReg')
        span.style.display = 'none'
        let spanCity = document.querySelector('.spanCityReg')
        spanCity.style.display = 'none'
    },
    valiCity(h){
      if(this.citieValue !== ""){
        h.target.style.border = '1px solid #5B9CCF'
        let span = document.querySelector('.spanCity')
          span.style.display = 'none'
      }
    },
  },
  
    watch:{
      stateValue(){
        if(this.stateValue){
          this.citieValue = ""
        }
      }
    },
    mounted(){
      this.getNearby()
    }
}
</script>
<style scoped>
span{
    display: none;
    color: #cc1313;
    padding-top: 5px;
    padding-left: 5px;
}
.open{
  display: none;
}
.close{
  display: block;
}
#row{
  margin-bottom: 4%
}
.modal-body{
  max-height: 500px;
  overflow-y: auto;
  overflow-x: none;
}
.modal-backdrop{
  z-index: 1!important;
}
p {
    margin-top: 0;
    margin-bottom: 0.5rem!important;
}
.bodyResultContainer{
  margin-top: 3%;
  padding-bottom: 2%;
}
.bodyResultContainer:nth-child(1){
  margin-top: 0%;
}
.bodyResultContainer{
  border-bottom: 1px #d3d3d3 solid;
}
#titleCity{
  font-weight: bolder;
  color: #001281;
  font-size: 1.2rem;
}
#buttonSendSearch{
  width: 33%;
  border: none;
  background: #FFF;
  font-weight: 600;
  color: #0122dc;
  gap: 2px;
  border: 1px #0122dc solid;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
#buttonSendSearch:hover{
    color: #FFFF;
    background: #0122dc;
    border: none;
    transition: 0.6s;
}
.btn-close{
  background: url('@/assets/allImages/closeOrange.png');
  width: 24px;
  height: 24px;
  background-repeat: no-repeat;
  background-position: center;
}
label{
  color: #727070!important
}
.modal-title{
  color: #727070!important
}
</style>