<template> 
    <div>
        <whats-app-fixed></whats-app-fixed>
        <other-components-header></other-components-header>
        <dropdown-servicos></dropdown-servicos>
        <div class="allContainer">
            <div class="titleContainer">
                <p class="title">{{ $store.getters.getItemService.serviceName}}</p>
            </div>
            <div>
                <p class="description">{{ $store.getters.getItemService.description }}</p>
            </div>
            <div class="stepContainer">
                <router-link to="/" class="setaStep"><img src="@/assets/stepsImages/setaStep.svg"></router-link>
                <div class="stepOne"><img src="@/assets/stepsImages/stepOne.svg" class="stepOneImg"></div>
            </div>
            <div><p class="fillIn">Preencha os campos para iniciarmos o serviço.</p></div>
            <div class="rowContainer">
                <div class="row" id="row">
                    <div class="col-12 p-0" >
                        <label class="mb-1 label_default"
                        >Nome</label
                        >
                        <input id="inputForm"
                        placeholder="Nome"
                        type="text"
                        class="input_default form-control"
                        v-model="userName"
                        @input="validationName"
                        />
                        <span class="spanRequiredName">Insira um Nome válido</span>
                    </div>
                    <div class="col-12 p-0" >
                        <label class="mb-1 label_default"
                        >E-mail</label
                        >
                        <input id="inputForm"
                        placeholder="Seu e-mail"
                        type="email"
                        class="input_default form-control"
                        v-model="userEmail"
                        @input="validationMail"
                        />
                        <span id="spanRequiredMail" class="spanRequiredMail">Insira um E-mail válido</span>
                    </div>
                    <div class="col-12 p-0" >
                        <label class="mb-1 label_default"
                        >Telefone/WhatsApp</label
                        >
                        <input id="inputForm"
                        placeholder="(00) 00000-0000"
                        type="text"
                        class="input_default form-control"
                        v-model="userTel"
                        @input="validationTel"
                        v-mask="'(##) #####-####'"
                        />
                        <span class="spanRequiredTel">Insira um Telefone válido</span>
                    </div>
                </div>
            </div>
            <div class="buttonContainerSearch">
                <button @click="toGo" class="searchButton">                      
                    Prosseguir
                    <img src="@/assets/headerImages/arrowButton.svg" class="arrowInButton">
                </button>
            </div>
        </div>
        <div>
            <footer-in-footer></footer-in-footer>
        </div>
    </div>
</template>

<script>
import dropdownServicos from '@/components/Header/dropdownServicos.vue'
import whatsAppFixed from '@/utils/whatsAppFixed.vue';
import otherComponentsHeader from '@/components/Header/otherComponentsHeader.vue';
import footerInFooter from '@/components/Footer/footerInFooter.vue';
import { HTTP } from '@/api_system';

export default {
    components:{
        otherComponentsHeader,
        footerInFooter,
        whatsAppFixed,
        dropdownServicos
    },
    data(){
        return{
            showDisplay: false,
            userName:"",
            userEmail:"",
            userTel:"",
        }
    },
    methods:{
        validationMail(k){
            let email = this.userEmail;
            var pattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
            
            if(email.match(pattern)){
                k.target.style.border = '1px solid #5B9CCF'
                let span = document.querySelector('.spanRequiredMail')
                span.style.display = 'none'
            }else{
                k.target.style.border = '2px solid #cc1313'
                let span = document.querySelector('.spanRequiredMail')
                span.style.display = 'block'
            }
        },
        validationName(x){
            if(this.userName.length > 0 && this.userName.length < 3){
                x.target.style.border = '2px solid #cc1313'
                let span = document.querySelector('.spanRequiredName')
                span.style.display = 'block'
            }else if(this.userName.length === 0){
                x.target.style.border = '1px solid #5B9CCF'
                let span = document.querySelector('.spanRequiredName')
                span.style.display = 'none'
            }
            else{
                x.target.style.border = '1px solid #5B9CCF'
                let span = document.querySelector('.spanRequiredName')
                span.style.display = 'none'
            }
        },
        validationTel(h){
            if(this.userTel.length > 0 && this.userTel.length < 15){
                h.target.style.border = '2px solid #cc1313'
                let span = document.querySelector('.spanRequiredTel')
                span.style.display = 'block'
            }else if(this.userTel.length > 15){
                h.target.style.border = '2px solid #cc1313'
                let span = document.querySelector('.spanRequiredTel')
                span.style.display = 'block'
            }
            else{
                h.target.style.border = '1px solid #5B9CCF'
                let span = document.querySelector('.spanRequiredTel')
                span.style.display = 'none'
            }
        },
        nameUser(){
            this.$store.commit("inputUser", {userName: this.userName, emailUser: this.userEmail, telUser: this.userTel})
        },
        toGo(){
            if(!this.userEmail && !this.userName && !this.userTel){
                let span = document.querySelector('.spanRequiredName')
                span.style.display = 'block'
                let spanMail = document.querySelector('.spanRequiredMail')
                spanMail.style.display = 'block'
                let spanTel = document.querySelector('.spanRequiredTel')
                spanTel.style.display = 'block'
                
            }else if(!this.userEmail && !this.userName && this.userTel){
                let span = document.querySelector('.spanRequiredName')
                span.style.display = 'block'
                let spanMail = document.querySelector('.spanRequiredMail')
                spanMail.style.display = 'block'
            }else if(!this.userEmail && !this.userTel && this.userName){
                let spanTel = document.querySelector('.spanRequiredTel')
                spanTel.style.display = 'block'
                let spanMail = document.querySelector('.spanRequiredMail')
                spanMail.style.display = 'block'
            }else if(!this.userTel && !this.userName && this.userEmail){
                let spanTel = document.querySelector('.spanRequiredTel')
                spanTel.style.display = 'block'
                let span = document.querySelector('.spanRequiredName')
                span.style.display = 'block'
            }else if(!this.userTel && this.userName && this.userEmail){
                let spanTel = document.querySelector('.spanRequiredTel')
                spanTel.style.display = 'block'
            }else if( !this.userName && this.userEmail && this.userTel){
                let span = document.querySelector('.spanRequiredName')
                span.style.display = 'block'
            }else if(!this.userEmail && this.userName && this.userTel ){
                let spanMail = document.querySelector('.spanRequiredMail')
                spanMail.style.display = 'block'
            }
            else if (this.userName.length < 3 ){
                let span = document.querySelector('.spanRequiredName')
                span.style.display = 'block'
            }
            else if (this.userTel.length > 0 && this.userTel.length < 15){
                let span = document.querySelector('.spanRequiredTel')
                span.style.display = 'block'
            }
            else if (this.userTel.length > 15){
                let span = document.querySelector('.spanRequiredTel')
                span.style.display = 'block'
            }
            else{
                let email = this.userEmail;
                var pattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
                
                if(email.match(pattern)){
                    let span = document.querySelector('.spanRequiredMail')
                    span.style.display = 'none'
                    this.$router.push('/secondStep')
                }else if(email.length === 0){
                    let span = document.querySelector('.spanRequiredMail')
                    span.style.display = 'none'
                }else{
                    let span = document.querySelector('.spanRequiredMail')
                    span.style.display = 'block'
                }
                this.nameUser()
            }
            
        },

        verify(){
            if(this.$store.state.service.serviceName === ""){
                this.$router.push('/')
            }
        },

        tokenRequest() {
            HTTP.post('services/app/PixBB/OAuthGenerateToken').then(res => {
                const { result } = res.data;
                const token = result.access_token.split(' ')[1]
                this.$store.commit('setToken', token)
            }).catch(err => console.log(err))
        }
    },
    mounted(){
        setInterval(() => {
            this.tokenRequest()
        }, 9000);
         this.verify()
    }
    
    
}
</script>

<style scoped>
.description {
    width: 50%;
    margin: 0 auto;
    text-align: center;
    font-size: 1rem;
    line-height: 1.6;
    font-weight: 500;
    color: #444243ad;
}

span{
    display: none;
    color: #cc1313;
    padding-top: 5px;
    padding-left: 5px;
}
.title{
    margin: 0;
    font-size: 1rem;
}
.displayShow {
    display: none;
}
.arrowInButton{
    width: 9px;
}
.titleContainer{
    display: flex;
    justify-content: center;
    background: #F7F7FB;
    border-radius: 24px;
    width: 30%;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 45px;
    text-align: center;
    color: #465EFF;
    height: 50px;
    margin-bottom: 3%;
}
.allContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
    flex-direction: column;
}
.stepContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 2%;
}
.stepOne{
    margin-right: 43%;
}
.setaStep{
    margin-right: 43%;
}

@media screen and (max-width:800px){
    .setaStep{
        margin-right: 20%;
    }
    .stepOne{
     margin-right: 20%;
    }
}
@media screen and (min-width:600px){
    .rowContainer{
        min-width: 612px;
    }
}
@media screen and (max-width:600px){
    .titleContainer {
        width: 73%;
        margin-bottom: 3%;
    }
    .title {
        font-size: max(1.3vw, 20px);
    }
    .stepOne {
        margin-right: 4%;
        width: 80%;
    }
    .stepOneImg{
        width: 90%;
    }
    .setaStep {
        margin-right: 9%;
    }
    .rowContainer{
        width: 90%!important;
    }
    .searchButton {
        width: 34%!important;
    }
    .buttonContainerSearch{
        margin-bottom: 14%!important;
    } 
}
@media screen and (max-width:450px){
    .titleContainer {
        width: 95%;
    }
}
@media screen and (max-width:420px){
    .stepOne {
        margin-right: -6%;
        width: 80%;
    }
    .stepOneImg{
        width: 82%;
    }
    .setaStep {
        margin-right: 6%;
    }
}
@media screen and (max-width:335px){
    .title {
        font-size: max(1.3vw, 16px)!important;
    }
}

#row{
    width: 100%;
    margin-top: 8%;
}
#inputForm{
    background: #FFFFFF;
    opacity: 0.8;
    border: 1px solid #1e59ae;
    border-radius: 20px;
}

label{
    font-style: normal;
    font-weight: 400;
    font-size: max(1vw, 13px);
    line-height: 24px;
    color: #797984;
    margin-top: 10px;
    margin-bottom: 0!important;
}
.fillIn{
    margin-top: 10%;
    font-style: normal;
    font-weight: 400;
    font-size: max(1.3vw, 23px);
    line-height: 24px;
    text-align: center;
    color: #1E59AE;
}
.searchButton{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 24px 22px;
    gap: 10px;
    background: #FFA620;
    border-radius: 30px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 113.2%;
    color: #FFFFFF;
    border: none;
    width: 22%;
    max-height: 49px;
    text-decoration: none;
}
.searchButton:hover{
    background: #f99500;
    transition: .7s;
}
.buttonContainerSearch{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 6%;
}
.rowContainer{
    display: flex!important;
    justify-content: center!important;
}
</style>