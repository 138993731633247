import axios from 'axios'

 export const HTTP =
    axios.create({
        baseURL: process.env.VUE_APP_ROOT_API + "/api/",
        responseType: "json",
        headers: {},
    })

 export const HTTPP = 
    axios.create({
        baseURL: process.env.VUE_APP_ROOT_API_PROD + "/api/",
        responseType: "json",
        headers: {},
    })
