<template>
    <div @click="validaOpenModal" class="container">
        <img src="@/assets/allImages/WHATSAPP.svg" class="whatsFixed" data-bs-toggle="modal" data-bs-target="#exampleModal">  
        <ModalWpp :valida="valida"></ModalWpp>
    </div>
</template>
<script>
import ModalWpp from './modalWpp.vue';
export default { 
  components:{
    ModalWpp
},
  data(){
    return{
      valida:0,
    }
  }, 
  methods:{
    validaOpenModal(){
        this.valida = 1
    },
    validation(){
      if(this.valida === 0 ){
        const backDrop = document.querySelector('.modal-backdrop');
        if(!backDrop){
          return
        }
        backDrop.classList.remove('modal-backdrop');
        document.location.reload(true);
      }
    }
  },
  mounted(){
    this.validation()
  }
}
</script>
<style >
.container{
  position: absolute;
  z-index: 999999;
}
.whatsFixed{
  margin-left: 90%;
  position: fixed;
  margin-top: 26%;
  cursor: pointer;
}
  .wppIcon{
    padding-left: 1.5%;
}
  #buttonSend{
    width: 33%;
    border: none;
    background: #FFF;
    font-weight: 600;
    color: #00BB2D;
    gap: 2px;
    border: 1px #00BB2D solid;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
#buttonSend:hover{
    color: #FFFF;
    background: #00BB2D;
    border: none;
    transition: 0.6s;
}
  #buttonClose{
    width: 15%;
    margin: 0;
    border: none;
    background: #FFF;
    font-weight: 600;
    color: #f33434;
    gap: 2px;
    border: 1px #f33434 solid;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  #buttonClose:hover{
    color: #FFFF;
    background: #f33434;
    border: none;
    transition: 0.6s;
}
  .modal-title{
    text-align: center;
  }
  #row{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
#rowTwo{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.4%;
}
#inputForm{
    background: #FFFFFF;
    opacity: 0.8;
    border: 1px solid #1e59ae;
    border-radius: 20px;
}
#divFormTwoWpp{
  margin-right: 8%;
}
#divFormWpp{
  margin-right: 8.5%;
}
.col-9{
    margin-top: 0.4%;
}
.modal-open{
  padding-right: 0px!important;
}
.modal-backdrop{
  z-index: 0!important;
}

.fixedContainer{
  width: 100%;
  max-width: 100%;
  position: fixed;
  z-index: 1000;
  margin-left: 90%;
  margin-top: 40%;
  cursor: pointer;
}
@media screen and (min-width:2000px){
  .fixedContainer{
    width: 110%!important;
    max-width: 110%!important;
    position: fixed!important;
    z-index: 995!important;
    margin-left: 91%!important;
    margin-top: 20%!important;
    height: 150px!important;
  }
}
@media screen and (max-width:1070px){
  .whatsFixed{
    margin-left: 85%!important;
  }
  .fixedContainer{
    width: 80%!important;
    max-width: 80%!important;
    position: fixed!important;
    margin-left: 85%!important;
    margin-top: 20%!important;
    height: 150px!important;
  }
}
@media screen and (max-width:985px){
  #buttonSend{
    width: 46%!important;
  }
  #buttonClose{
    width: 18%!important;
  }
}
@media screen and (max-width:800px){
  .fixedContainer{
    position: fixed;
    margin-left: 88%!important;
    margin-top: 40%;
    width: 90%!important;
    max-width: 90%!important;
    height: 90px!important;
  }
  .whatsFixed{
    height: 13%!important;
    margin-left: 80%!important;
  }
}
@media screen and (max-width:605px){
  .whatsFixed{
    height: 11%!important;
  }
  .fixedContainer{
    width: 50%!important;
    max-width: 50%!important;
    position: fixed!important;
    margin-left: 85%!important;
    margin-top: 60%!important;
    height: 150px!important;
  }
}
@media screen and (max-width:497px){
  .fixedContainer{
    margin-left: 75%!important;
  }
  .whatsFixed {
    margin-left: 75%!important;
  }
}
@media screen and (max-width:402px){
  #buttonSend{
    width: 50%!important;
    font-size: 0.9rem;
  }
  #buttonClose{
    width: 47%!important;
    font-size: 0.9rem;
  }
}
@media screen and (max-width:388px){
  .whatsFixed{
    margin-left: 73%!important;
    height: 12%!important;
  }
}
@media screen and (max-width:360px){
  .whatsFixed{
    margin-left: 70%!important;
    height: 10%!important;
  }
}

</style>