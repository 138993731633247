<template>
    <div class="backgroundConvite">
            <div class="textConvite">
                <h1 class="weCan">Dúvidas? Entre em contato conosco!</h1>
            </div>
            <div>
                <div class="row" id="row">
                    <div class="col-6 p-0" >
                        <label class="mb-1 label_default"
                        >Nome</label
                        >
                        <input id="inputForm"
                        placeholder="Nome"
                        type="text"
                        class="input_default form-control requiredName"
                        v-model="userName"
                        @input="validationName"
                        />
                        <span class="spanRequiredName">Insira um Nome válido</span>
                    </div>
                    <div class="col-6 p-0" >
                        <label class="mb-1 label_default"
                        >E-mail</label
                        >
                        <input id="inputFormMail"
                        placeholder="Seu e-mail"
                        type="email"
                        class="input_default form-control required"
                        v-model="userEmail"
                        @input="validationMail"
                        />
                        <span class="spanRequiredMail">Insira um E-mail válido</span>
                    </div>
                </div>
                <div class="row" id="rowTwo">
                    <div class="col-1 p-0" id="divFormTwo">
                        <label class="mb-1 label_default"
                            >Estado</label
                        >
                        <select id="inputForm"
                            placeholder="Selecione"
                            type="text"
                            class="input_default form-control"
                            v-model="state"
                            @change="validationState"
                            >
                            <option value="" disabled selected>Selecione</option>
                            <option v-for="state in uf" :key="state.ufCity" :value="state.ufCity">{{state.ufCity}}</option>
                        </select>
                        <span class="spanRequiredState">Insira o Estado</span>
                    </div>
                    <div class="col-4 p-0" id="divForm">
                        <label class="mb-1 label_default"
                        >Cidade</label
                        >
                        <select id="inputForm"
                            placeholder="Selecione"
                            type="text"
                            class="input_default form-control"
                            v-model="userCity"
                            @change="validationCity"
                            >
                            <option value="" disabled selected>Selecione</option>
                            <option v-for="state in optionCity" :key="state.nameCity" :value="state.nameCity">{{state.nameCity}}</option>
                        </select>
                        <span class="spanRequiredCity">Insira a Cidade</span>
                    </div>
                    
                </div>
            </div>
            <div class="submitContainer">
                <button v-on:click="apiWpp()" class="submitButton">Enviar</button>
            </div>
        </div>
</template>

<script>
import { HTTP } from "@/api_system"
export default {
    data(){
        return{
            uf:[],
            optionCity:[],
            userName:"",
            userEmail:"",
            state:"",
            userCity:"",
        }
    },
    methods:{
        getUf(){
            HTTP.get("services/app/City/GetByUF")
            .then(response => {
                this.uf = response.data.result;
            })
            .catch(error => {
                console.log(error);
            });
        },
        getCity(){
            HTTP.get(`services/app/City/GetByUF?nameUf=${this.state}`)
            .then(response => {
                this.optionCity = response.data.result;
            })
            .catch(error => {
                console.log(error);
            });
        },
        apiWpp(){
            if(!this.userEmail && !this.userName && !this.state && !this.userCity){
                let span = document.querySelector('.spanRequiredName')
                span.style.display = 'block'
                let spanState = document.querySelector('.spanRequiredState')
                spanState.style.display = 'block'
                let spanCity = document.querySelector('.spanRequiredCity')
                spanCity.style.display = 'block'
                let spanMail = document.querySelector('.spanRequiredMail')
                spanMail.style.display = 'block'
            }
            else if (!this.state && !this.userCity && !this.userEmail && this.userName){
                let span = document.querySelector('.spanRequiredState')
                span.style.display = 'block'
                let spanCity = document.querySelector('.spanRequiredCity')
                spanCity.style.display = 'block'
                let spanMail = document.querySelector('.spanRequiredMail')
                spanMail.style.display = 'block'
            }else if(this.state && !this.userCity && !this.userEmail && !this.userName){
                let spanCity = document.querySelector('.spanRequiredCity')
                spanCity.style.display = 'block'
                let spanMail = document.querySelector('.spanRequiredMail')
                spanMail.style.display = 'block'
                let span = document.querySelector('.spanRequiredName')
                span.style.display = 'block'
            }else if(!this.state && !this.userCity && !this.userName && this.userEmail){
                let spanCity = document.querySelector('.spanRequiredCity')
                spanCity.style.display = 'block'
                let span = document.querySelector('.spanRequiredName')
                span.style.display = 'block'
                let spanState = document.querySelector('.spanRequiredState')
                spanState.style.display = 'block'
            }else if(this.userName && this.userEmail && !this.state && !this.userCity ){
                let spanCity = document.querySelector('.spanRequiredCity')
                spanCity.style.display = 'block'
                let spanState = document.querySelector('.spanRequiredState')
                spanState.style.display = 'block'
            }else if(!this.userName && !this.userEmail && this.state && this.userCity ){
                let span = document.querySelector('.spanRequiredName')
                span.style.display = 'block'
                let spanMail = document.querySelector('.spanRequiredMail')
                spanMail.style.display = 'block'
            }else if(this.userName && !this.userEmail && this.state && !this.userCity ){
                let spanCity = document.querySelector('.spanRequiredCity')
                spanCity.style.display = 'block'
                let spanMail = document.querySelector('.spanRequiredMail')
                spanMail.style.display = 'block'
            }else if(this.userName && this.userEmail && this.state && !this.userCity ){
                let spanCity = document.querySelector('.spanRequiredCity')
                spanCity.style.display = 'block'
            }
            else if(!this.userName && this.userEmail && this.state && this.userCity ){
                let span = document.querySelector('.spanRequiredName')
                span.style.display = 'block'
            }
            else if(this.userName && !this.userEmail && this.state && this.userCity ){
                let spanMail = document.querySelector('.spanRequiredMail')
                spanMail.style.display = 'block'
            }
            else{
                let email = this.userEmail;
                let pattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
                
                if(email.match(pattern)){
                    let span = document.querySelector('.spanRequiredMail')
                    span.style.display = 'none'
                    window.location.href = `https://api.whatsapp.com/send?phone=551132300318&text=Gostaria+de+tirar+d%C3%BAvidas+sobre+a+parceria+Ponto+F%C3%A1cil+%2B+Banco+do+Brasil`
                    // window.location.href = `https://api.whatsapp.com/send?phone=551132300318&text=Ol%C3%A1%20meu%20nome%20%C3%A9%20${this.userName}%20e%20meu%20email%20${this.userEmail}%20sou%20da%20cidade%20de%20${this.userCity}%20${this.state}`
                }else{
                    let span = document.querySelector('.spanRequiredMail')
                    span.style.display = 'block'
                }
                
            }
        },
        validationState(z){
            if(this.state !== ""){
                z.target.style.border = '1px solid #5B9CCF'
                let span = document.querySelector('.spanRequiredState')
                span.style.display = 'none'
            }
        },
        validationCity(h){
            if(this.userCity !== ""){
                h.target.style.border = '1px solid #5B9CCF'
                let span = document.querySelector('.spanRequiredCity')
                span.style.display = 'none'
            }
        },
        validationName(x){
            if(this.userName.length > 0 && this.userName.length < 3){
                x.target.style.border = '2px solid #cc1313'
                let span = document.querySelector('.spanRequiredName')
                span.style.display = 'block'
            }else if(this.userName.length === 0){
                x.target.style.border = '1px solid #5B9CCF'
                let span = document.querySelector('.spanRequiredName')
                span.style.display = 'none'
            }
            else{
                x.target.style.border = '1px solid #5B9CCF'
                let span = document.querySelector('.spanRequiredName')
                span.style.display = 'none'
            }
        },
        validationMail(k){
            let email = this.userEmail;
            var pattern = /^[^ ]+@[^ ]+\.[a-z]{2,3}$/;
            
            if(email.match(pattern)){
                k.target.style.border = '1px solid #5B9CCF'
                let span = document.querySelector('.spanRequiredMail')
                span.style.display = 'none'
            }else{
                k.target.style.border = '2px solid #cc1313'
                let span = document.querySelector('.spanRequiredMail')
                span.style.display = 'block'
            }
        },
    },
    watch:{
        state(){
            if(this.state){
                this.getCity()
            }
        },
    },
    mounted(){
        this.getUf()
    }
}
</script>

<style scoped>
span{
    display: none;
    color: #cc1313;
    padding-top: 5px;
    padding-left: 5px;
}

@media screen and (max-width:600px){
    .col-6 {
        width: 85%!important;
    }
    #divFormTwo{
        width: 85%;
        margin-bottom: 1%;
        margin-right: 0%!important;
    }
    #rowTwo{
        flex-direction: column;
    }
    #divForm{
        margin-left: 0!important;
        width: 85%;
    }
    .weCan{
        text-align: center!important;
        font-size: 7vw!important;
    }
    .coming{
        text-align: center!important;
        font-size: 4.6vw!important;
    }
    .simple{
        text-align: center!important;
        font-size: 6.6vw!important;
    }
}
.backgroundConvite{
    background-color: rgb(247, 250, 255);
    width: 100%;
}
.textConvite{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 5%;
}
.weCan{
    font-style: normal;
    font-weight: 700;
    font-size: 2rem;
    line-height: 45px;
    color: #18408A;
    max-width: 100%;
}
.coming{
    margin-top: 0.5%;
    font-style: normal;
    font-weight: 400;
    font-size: 1.5vw;
    line-height: 27px;
    color: #797984;
    max-width: 100%;
}
.simple{
    font-style: normal;
    font-weight: 700;
    font-size: 2.2vw;
    line-height: 42px;
    color: #E0A638;
    max-width: 100%;
}
#row{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
#rowTwo{
    display: flex;
    align-items: center;
    justify-content: center;
}
/* #inputForm{
    background: #FFFFFF;
    opacity: 0.8;
    border: 1px solid #5B9CCF;
    border-radius: 20px;
} */
#inputFormMail{
    background: #FFFFFF;
    opacity: 0.8;
    border: 1px solid #1e59ae;
    border-radius: 20px;
}
.col-6{
    margin-top: 0.4%;
}
.submitButton{
    
    width: 50%;
    height: 2.5rem;
    background: #FFA620;
    border-radius: 30px;
    font-weight: 600;
    font-size: max(1vw, 15px);
    color: #FFF;
    gap: 2px;
    border: 1px #FFF solid;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 1%;
}
.submitButton:hover{
    background: #ea8c00;
    transition: 0.6s;
}
.submitContainer{
    margin-top: 3%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 4%;
}
label{
    font-style: normal;
    font-weight: 400;
    font-size: max(1vw, 13px);
    line-height: 24px;
    color: #797984;
}
#divForm{
    margin-top: 0.2%;
}
#divFormTwo{
    margin-top: 0.2%;
    margin-right: 8%;
}
</style>