import Vue from 'vue'
import App from './App.vue'
import router from './router/router.js'
import VueTheMask from 'vue-the-mask'
import store from './store.js'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueMask from 'v-mask';

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

// import VeeValidate from "vee-validate";
// Vue.use(VeeValidate);

const options = {
  position: "top-right",
    timeout: 4000,
    closeOnClick: false,
    pauseOnFocusLoss: false,
    pauseOnHover: false,
    draggable: false,
    draggablePercent: 0.6,
    showCloseButtonOnHover: true,
    hideProgressBar: false,
    closeButton: "button",
    icon: true,
    rtl: false
};

Vue.use(Toast, options);
Vue.use(VueMask)

library.add(fas)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueTheMask)
Vue.config.productionTip = false

new Vue({
  router,
  VueTheMask,
  store,
  render: h => h(App),
}).$mount('#app')



import { ValidationProvider, extend, ValidationObserver, configure } from "vee-validate";

import * as rules from "vee-validate/dist/rules";
import { messages } from "vee-validate/dist/locale/pt_BR.json";

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
configure({
    classes: {
        valid: "is-valid",
        invalid: "is-invalid",
        dirty: ["is-dirty", "is-dirty"] // multiple classes per flag!
            // ...
    },
    inject: false
});

Object.keys(rules).forEach(rule => {
    extend(rule, {
        ...rules[rule], // copies rule configuration
        message: messages[rule] // assign message
    });
});
