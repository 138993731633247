<template>
  <div id="app">
    <whats-app-fixed></whats-app-fixed>
    <header-component-vue></header-component-vue>
    <body-component-vue></body-component-vue>
    <footer-component-vue></footer-component-vue>
  </div>
</template>

<script>
import headerComponentVue from '@/components/Header/headerComponent.vue';
import bodyComponentVue from '@/components/Body/bodyComponent.vue';
import footerComponentVue from '@/components/Footer/footerComponent.vue';
import whatsAppFixed from '@/utils/whatsAppFixed.vue';

export default {
  components: {
    headerComponentVue,
    bodyComponentVue,
    footerComponentVue,
    whatsAppFixed
  }
}
</script>

<style>
  #app{
    overflow-x: hidden;
  }
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


</style>
