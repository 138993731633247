<template>
  <div class="headerContainer">
    <div class="logoContainer">
      <img src="@/assets/headerImages/logoPF.svg" class="logoPF" />
      <img src="@/assets/headerImages/plusIcon.svg" class="plusIcon" />
      <img src="@/assets/headerImages/logoBB.svg" class="logoBB" />
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.teste {
  z-index: 1;
}
.headerContainer {
  width: 100%;
  height: 900px;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("@/assets/headerImages/imgHeader.png");
  background-position: center;
}
.logoContainer {
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: row;
  margin-top: 2.5%;
  margin-left: 7.5%;
  width: 40%;
}
.logoPF {
  width: 25%;
  max-width: 30%;
}
.plusIcon {
  padding-left: 2.5%;
  max-width: 10%;
  width: 6%;
}
.logoBB {
  padding-left: 2.5%;
  max-width: 40%;
  width: 40%;
}
.imgHeader {
  width: 100%;
  position: relative;
}
.imgHeaderMobile {
  width: 100%;
  position: relative;
}

@media screen and (min-width: 2000px) {
  .logoContainer {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: row;
    margin-top: 1.5% !important;
    margin-left: 7.5% !important;
    width: 30% !important;
  }
}
@media screen and (max-width: 1700px) {
  .headerContainer {
    height: 650px;
  }
}
@media screen and (max-width: 1400px) {
  .headerContainer {
    height: 595px;
  }
}
@media screen and (max-width: 1200px) {
  .headerContainer {
    height: 460px;
  }
}
@media screen and (max-width: 930px) {
  .headerContainer {
    height: 400px;
  }
}
@media screen and (max-width: 800px) {
  .headerContainer {
    height: 330px;
  }
}
@media screen and (max-width: 600px) {
  .headerContainer {
    height: 286px;
  }
  .logoContainer[data-v-3f8e5004] {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: row;
    margin-top: 2.5% !important;
    margin-left: 4.5% !important;
    width: 59% !important;
  }
}
@media screen and (max-width: 540px) {
  .logoContainer {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: row;
    margin-top: 2.5% !important;
    margin-left: 7.5% !important;
    width: 50% !important;
  }
}

@media screen and (max-width: 500px) {
  .headerContainer {
    width: 100%;
    height: 708px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("@/assets/headerImages/imgHeaderMobile.png");
    background-position: center;
  }
}
@media screen and (max-width: 450px) {
  .headerContainer {
    width: 100%;
    height: 582px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("@/assets/headerImages/imgHeaderMobile.png");
    background-position: center;
  }
}
@media screen and (max-width: 400px) {
  .headerContainer {
    width: 100%;
    height: 533px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("@/assets/headerImages/imgHeaderMobile.png");
    background-position: center;
  }
}
@media screen and (max-width: 360px) {
  .headerContainer {
    width: 100%;
    height: 500px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("@/assets/headerImages/imgHeaderMobile.png");
    background-position: center;
  }
}
@media screen and (max-width: 310px) {
  .headerContainer {
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("@/assets/headerImages/imgHeaderMobile.png");
    background-position: center;
  }
}
</style>
