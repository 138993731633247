<template>
    <div class="comoFuncContainer">
        <div class="comoFunc">
            <div class="funcContainer">
                <p class="funcImg">Como funciona nosso atendimento:</p>
            </div>
            <div class="funcPasso">
                <div class="passoContainer" id="passoOne">
                    <div class="imgPassoDiv"><img class="passoImg" src="@/assets/headerImages/passoOneImg.svg"></div>
                    <div class="txtPassoDiv">
                        <p class="passo">1° Passo</p>
                        <p class="passoTxt">Escolha abaixo o tipo de serviço desejado</p>
                    </div>
                </div>
                <div class="passoContainer" id="passoTwo">
                    <div class="imgPassoDiv"><img class="passoImg" src="@/assets/headerImages/passoTwoImg.svg"></div>
                    <div class="txtPassoDiv">
                        <p class="passo">2° Passo</p>
                        <p class="passoTxt">Preencha os dados solicitados</p>
                    </div>
                </div>
                <div class="passoContainer" id="passoTree">
                    <div class="imgPassoDiv"><img class="passoImg" src="@/assets/headerImages/passoTreeImg.svg"></div>
                    <div class="txtPassoDiv" id=txtTree>
                        <p class="passo">3° Passo</p>
                        <p class="passoTxt">Pague com o QR Code Pix</p>
                    </div>
                </div>
                <div class="passoContainer">
                    <div class="imgPassoDiv"><img class="passoImg" src="@/assets/headerImages/passoFourImg.svg"></div>
                    <div class="txtPassoDiv" id="txtFour">
                        <p class="passo">4° Passo</p>
                        <p class="passoTxt">O pedido será enviado a nossa equipe que irá cuidar do seu atendimento</p>
                    </div>
                </div>
                <div class="passoContainer">
                    <div class="imgPassoDiv"><img class="passoImg" src="@/assets/headerImages/passoFiveImg.svg"></div>
                    <div class="txtPassoDiv" id="txtFive">
                        <p class="passo">5° Passo</p>
                        <p class="passoTxt">Você receberá uma mensagem no seu Whatsapp com a conclusão do serviço</p>
                    </div>
                </div>
            </div>
            <div class="setaContainer">
                <img src="@/assets/headerImages/setaCirculo.svg" class="seta" id="setaCirculo">
                <img src="@/assets/headerImages/setaSeta.svg" class="seta" id="setaSeta">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    
}
</script>

<style scoped>
.funcContainer{
    margin-top: 2%;
}
.funcImg{
    font-style: normal;
    font-weight: 700;
    font-size: max(2vw, 13px);
    line-height: 130%;
    color: #4C4D5B;
}
.passo{
    font-style: normal;
    font-weight: 600;
    font-size: max(1.1vw, 14px);
    line-height: 150%;
    text-align: center;
    color: #4C4D5B;
    margin-bottom: 2px;
}
.imgPassoDiv{
    display: flex;
    align-items: center;
    justify-content: center;
}
.txtPassoDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
#txtFour{
    width: 110%;
    display: flex;
    align-items: center;
    justify-content: center;
}
#txtFive{
    width: 110%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.passoImg{
    max-width: 100%;
}
.passoTxt{
    font-style: normal;
    font-weight: 400;
    font-size: max(1vw, 15px);
    line-height: 150%;
    text-align: center;
    letter-spacing: -0.01em;
    color: #797984;
    min-height: 64px;
    margin-bottom: 2px;
}
@media screen and (max-width:1440px){
    #txtFive{
        width: 115%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #txtFour{
        width: 113%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
@media screen and (max-width:1024px){
    #txtTree{
        max-width: 86%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #txtFour{
        width: 120%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #txtFive{
        width: 120%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .passoImg{
        max-width: 80%;
    }
}
@media screen and (max-width:600px){
    #txtTree{
        width: 120%;
    }
    .funcPasso{
        flex-direction: column!important;
        align-items:unset!important;
        margin-top: 2%!important;
    }
    .passoContainer{
        flex-direction: row!important;
        width: 94%!important;
        margin-top: 3%!important;
    }
    .txtPassoDiv{
        align-items: flex-start!important;
        margin-left: 9%;
        width: 120%;
        margin-top: 2%!important;
    }
    .passoImg{
        min-width: 64px!important;
        max-width: 64px!important;
    }
    .passoTxt{
        min-width: 192px;
        text-align: unset;
    }
    .funcImg{
        font-size: max(2vw, 21px);
        text-align: center;
    }
    .comoFunc{
        margin-top: -20px!important;
    } 
    .comoFuncContainer{
        padding-bottom: 7%;
    }
}
@media screen and (max-width:280px){
    .txtPassoDiv {
        align-items: flex-start!important;
        margin-left: 9%;
        width: 120%;
        margin-top: -4%!important;
    }
    .passoImg {
        min-width: 45px!important;
        max-width: 45px!important;
    }
    .passoTxt {
        min-width: 141px;
        text-align: unset;
    }
}
.passoContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: 2.5%;
    margin-right: 2.5%;
    width: 50%;
    max-height: 230px;
    margin-top: 2%;
}
.comoFuncContainer{
    background-color: rgb(241, 241, 241);
    display: flex;
    justify-content: center;
    width: 100%;
}
.comoFunc{
    margin-top: -50px;
    width: 80%;
    background-color: rgb(255, 255, 255);
    border-radius: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.funcPasso{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5%;
    margin-bottom: 2%;
}
.setaContainer{
    position: relative;
    width: 5%;
    height: 5%;
    padding-top: 1%;
    max-width: 5%;
}
#setaSeta{
    position: absolute;
    width: 20%;
    right: 63.5%;
    transform: translateY(-5px);
    animation-name: moveseta;
    animation-iteration-count: infinite;
    animation-duration: 1.2s;
    animation-timing-function: ease;
}
.seta{
    z-index: 1;
    height: 40px;
    margin-top: -1%;
    max-width: 100%;
}
@keyframes moveseta{
    to{
        transform: translateY(5px);
    }
}
@media screen and (max-width:2561px){
    #setaSeta{
        right: 69%!important;
        width: 18%!important;
    }
}
@media screen and (min-width:2000px){
    #setaSeta{
        right: 70.5%!important;
    }
}
@media screen and (max-width:1024px){
     #setaSeta{
        left: 35%;
        width: 30%!important;
    }
}
@media screen and (max-width:2200px){
    #setaSeta{
        right: 64.5%!important;
    }
}
@media screen and (max-width:1800px){
    #setaSeta{
        right: 62.5%!important;
    }
}
@media screen and (max-width:1550px){
    #setaSeta{
        left: 24%;
        width: 22%!important;
    }
}
@media screen and (max-width:1300px){
    #setaSeta{
        left: 27%;
        width: 25%!important;
    }
}
@media screen and (max-width:1200px){
    #setaSeta{
        left: 36%;
        width: 27%!important;
    }
}
@media screen and (max-width:600px){
    #setaSeta{
        width: 62%!important;
        left: 53%!important;
        margin-top: -41px!important;
    }
    .seta{
        z-index: 1!important;
        height: 40px!important;
        margin-top: 156%!important;
        max-width: 200%!important;
    }
}
@media screen and (max-width:550px){
    #setaSeta{
        left: 62%!important;
    }
}
@media screen and (max-width:520px){
    #setaSeta{
        left: 73%!important;
    }
}
</style>