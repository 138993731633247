<template>
 <div>
   <whats-app-fixed></whats-app-fixed>
   <other-components-header></other-components-header>
   <div class="allContainer">
     <div class="titleContainer">
       <p class="title">{{$store.state.service.serviceName}}</p>
     </div>
     <div class="stepContainer">
       <router-link to="/firstStep" class="setaStep"><img src="@/assets/stepsImages/setaStep.svg" alt=""></router-link>
       <div class="stepTwo"><img src="@/assets/stepsImages/stepTwo.svg" class="stepTwoImg" alt=""></div>
     </div>
     <div>
       <p class="fillIn">Complete os campos dos seus dados.</p>
     </div>
     <div class="rowContainer">
       <div class="row" id="row">
         <div class="col-12 p-0">
           <label class="mb-1 label_default">CPF</label>
           <input id="inputForm" placeholder="000.000.000-00" v-mask="'###.###.###-##'" class="input_default form-control" v-model="userCpf" @input="validaCpf" autocomplete="off" maxlength="14" />
           <span class="spanRequiredCpf">Insira um CPF válido</span>
         </div>
         <div class="col-12 p-0">
           <label class="mb-1 label_default">Endereço</label>
           <input id="inputForm" placeholder="Seu Endereço" type="text" class="input_default form-control" v-model="userEnd" />
           <span class="spanRequiredEnd">Insira o Endereço</span>
         </div>
       </div>
       <div class="row" id="rowTwo">
         <div class="col-4 p-0" id="divFormTwo">
           <label class="mb-1 label_default">Estado</label>
           <select id="inputForm" placeholder="Selecione" type="text" class="input_default form-control" v-model="state" @change="validationState">
             <option value="" disabled selected>Selecione</option>
             <option v-for="state in uf" :key="state.ufCity" :value="state.ufCity">{{state.ufCity}}</option>
           </select>
           <span class="spanRequiredState">Insira o Estado</span>
         </div>
         <div class="col-7 p-0" id="divForm">
           <label class="mb-1 label_default">Cidade</label>
           <select id="inputForm" placeholder="Selecione" type="text" class="input_default form-control" v-model="userCity" @change="validationCity">
             <option value="" disabled selected>Selecione</option>
             <option v-for="state in optionCity" :key="state.nameCity" :value="state.nameCity">{{state.nameCity}}</option>
           </select>
           <span class="spanRequiredCity">Insira a Cidade</span>
         </div>

       </div>
     </div>
     <Service @valuesField="sendRequestValue" :dataItem="data" :load="load" v-if="data.result.parametrizations.length > 0" />
     <div class="buttonContainerSearch" v-else>
       <button @click="toGo" class="searchButton">
         Prosseguir
         <img src="@/assets/headerImages/arrowButton.svg" class="arrowInButton" alt="">
       </button>
       <span class="spanRequiredType">Serviço não permitido para este estado!</span>
     </div>
   </div>
   <div>
     <footer-in-footer></footer-in-footer>
   </div>
 </div>
</template>

<script>
// eslint-disable-next-line
import {
  HTTP
} from "@/api_system"
import whatsAppFixed from '@/utils/whatsAppFixed.vue';
import otherComponentsHeader from '@/components/Header/otherComponentsHeader.vue';
import footerInFooter from '@/components/Footer/footerInFooter.vue';
import Service from '@/components/service/indexService.vue';
import {
  mapGetters,
  mapActions
} from 'vuex'
export default {
  components: {
    otherComponentsHeader,
    footerInFooter,
    whatsAppFixed,
    Service,
    // services: () => import('@/components/service/indexService.vue')
  },
  data() {
    return {
      uf: [],
      state: "",
      optionCity: [],
      userCpf: "",
      userEnd: "",
      userCity: "",
      valueService: [''],
      data: [],
      nameCP: 'services',
      load: false
    }
  },
  computed: {
    ...mapGetters(['getItemService'])
  },
  methods: {
    ...mapActions(['getServicesDetails']),
    getUf() {
      HTTP.get("services/app/City/GetByUF")
        .then(response => {
          this.uf = response.data.result;
        })
        .catch(error => {
          console.log(error);
        });
    },
    getCity() {
      HTTP.get(`services/app/City/GetByUF?nameUf=${this.state}`)
        .then(response => {
          this.optionCity = response.data.result;
        })
        .catch(error => {
          console.log(error);
        });
    },
    validaCpf(x) {
      if (this.userCpf.length > 0 && this.userCpf.length < 14 || this.userCpf.length > 14) {
        x.target.style.border = '2px solid #cc1313'
        let span = document.querySelector('.spanRequiredCpf')
        span.style.display = 'block'
      } else if (this.userCpf.length === 0) {
        x.target.style.border = '1px solid #5B9CCF'
        let span = document.querySelector('.spanRequiredCpf')
        span.style.display = 'none'
      } else {
        x.target.style.border = '1px solid #5B9CCF'
        let span = document.querySelector('.spanRequiredCpf')
        span.style.display = 'none'
      }

    },
    validationState(z) {
      if (this.state !== "") {
        z.target.style.border = '1px solid #5B9CCF'
        let span = document.querySelector('.spanRequiredState')
        span.style.display = 'none'
      }
    },
    validationCity(h) {
      if (this.userCity !== "") {
        h.target.style.border = '1px solid #5B9CCF'
        let span = document.querySelector('.spanRequiredCity')
        span.style.display = 'none'
      }
    },
    infoInput() {
      this.$store.commit("inputUserSS", {
        cpfUser: this.userCpf,
        endUser: this.userEnd,
        state: this.state,
        city: this.userCity
      })
    },
    toGo(value) { 
      if (!this.state && !this.userCity && !this.userCpf && !this.userEnd) {
        let span = document.querySelector('.spanRequiredState')
        span.style.display = 'block'
        let spanCity = document.querySelector('.spanRequiredCity')
        spanCity.style.display = 'block'
        let spanCpf = document.querySelector('.spanRequiredCpf')
        spanCpf.style.display = 'block'
        let spanEnd = document.querySelector('.spanRequiredEnd')
        spanEnd.style.display = 'block'
      } else if (!this.state && !this.userCity && !this.userEnd && this.userCpf.length <= 13) {
        let span = document.querySelector('.spanRequiredState')
        span.style.display = 'block'
        let spanCity = document.querySelector('.spanRequiredCity')
        spanCity.style.display = 'block'
        let spanTwo = document.querySelector('.spanRequiredCpf')
        spanTwo.style.display = 'block'
        let spanEnd = document.querySelector('.spanRequiredEnd')
        spanEnd.style.display = 'block'
      } else if (this.userCpf.length == 11 && !this.state && !this.userCity) {
        let span = document.querySelector('.spanRequiredState')
        span.style.display = 'block'
        let spanCity = document.querySelector('.spanRequiredCity')
        spanCity.style.display = 'block'
      } else if (this.state && this.userCity && this.userCpf.length <= 13) {
        let spanTwo = document.querySelector('.spanRequiredCpf')
        spanTwo.style.display = 'block'
      } else if (this.userCpf && !this.userCity && !this.state && !this.userEnd) {
        let spanCity = document.querySelector('.spanRequiredCity')
        spanCity.style.display = 'block'
        let span = document.querySelector('.spanRequiredState')
        span.style.display = 'block'
        let spanEnd = document.querySelector('.spanRequiredEnd')
        spanEnd.style.display = 'block'
      } else if (!this.userCpf && !this.userCity && !this.state && this.userEnd) {
        let spanCity = document.querySelector('.spanRequiredCity')
        spanCity.style.display = 'block'
        let span = document.querySelector('.spanRequiredState')
        span.style.display = 'block'
        let spanCpf = document.querySelector('.spanRequiredCpf')
        spanCpf.style.display = 'block'
      } else if (!this.userCpf && !this.userCity && this.state && !this.userEnd) {
        let spanCity = document.querySelector('.spanRequiredCity')
        spanCity.style.display = 'block'
        let spanEnd = document.querySelector('.spanRequiredEnd')
        spanEnd.style.display = 'block'
        let spanCpf = document.querySelector('.spanRequiredCpf')
        spanCpf.style.display = 'block'
      } else if (this.userCpf && this.userCity && this.state && !this.userEnd) {
        let spanEnd = document.querySelector('.spanRequiredEnd')
        spanEnd.style.display = 'block'
      } else if (this.userCpf && !this.userCity && !this.state && this.userEnd) {
        let spanCity = document.querySelector('.spanRequiredCity')
        spanCity.style.display = 'block'
        let span = document.querySelector('.spanRequiredState')
        span.style.display = 'block'
      } else if (this.userCpf && !this.userCity && this.state && !this.userEnd) {
        let spanEnd = document.querySelector('.spanRequiredEnd')
        spanEnd.style.display = 'block'
        let spanCity = document.querySelector('.spanRequiredCity')
        spanCity.style.display = 'block'
      } else if (this.userCpf && !this.userCity && this.state && this.userEnd) {
        let spanCity = document.querySelector('.spanRequiredCity')
        spanCity.style.display = 'block'
      } else if (this.state && this.userEnd && !this.userCity) {
        let spanCity = document.querySelector('.spanRequiredCity')
        spanCity.style.display = 'block'
        let spanCpf = document.querySelector('.spanRequiredCpf')
        spanCpf.style.display = 'block'
      } else if (this.state && !this.userCity) {
        let spanCity = document.querySelector('.spanRequiredCity')
        spanCity.style.display = 'block'
      } else if (this.$store.state.service.serviceType === 6 && this.state !== "SP") {
        let spanType = document.querySelector('.spanRequiredType')
        spanType.style.display = 'block'
      } else if (this.$store.state.service.serviceType === 7 && this.state !== "SP") {
        let spanType = document.querySelector('.spanRequiredType')
        spanType.style.display = 'block'
      } else {
        this.infoInput()
        this.sendRequest(value)
      }

    },
    validationCpf(cpf) {
      let strCPF = cpf.replace(/\.|-/g, "")
      var Soma;
      var Resto;
      Soma = 0;
      if (strCPF == "00000000000") return false;
      for (let i = 1; i <= 9; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
      Resto = (Soma * 10) % 11;
      if ((Resto == 10) || (Resto == 11)) Resto = 0;
      if (Resto != parseInt(strCPF.substring(9, 10))) return false;
      Soma = 0;
      for (let i = 1; i <= 10; i++) Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
      Resto = (Soma * 10) % 11;

      if ((Resto == 10) || (Resto == 11)) Resto = 0;
      if (Resto != parseInt(strCPF.substring(10, 11))) return false;
      return true;
    },
    async getServices() {
      try {
        const result = await this.getServicesDetails()
        this.data = (result)
      } catch (error) {
        console.log(error)
      }
    },
    createJob({
      serviceValue,
      nameUser,
      cpfUser,
      endUser,
      emailUser,
      telUser,
      state,
      city
    }, values) {
      // value
      if (!this.validationCpf(cpfUser)) {
        let spanCpf = document.querySelector('.spanRequiredCpf')
        spanCpf.style.display = 'block'
        return false;
      }

      this.load = true
      const data = new FormData()

      const {
        serviceId
      } = this.$store.getters.getItemService
      const file = this.$store.getters.getFilesUpload

      data.append('value', serviceValue.replace(',', '.'))
      data.append('name', nameUser)
      data.append('cpf', cpfUser.replace(/\.|-/g, ""))
      data.append('address', endUser)
      data.append('email', emailUser)
      data.append('phone', telUser.replace(/[^0-9]+/g, ''))
      data.append('uf', state)
      data.append('city', city)
      data.append('serviceId', serviceId)
      data.append('dataTransaction', JSON.stringify(values))
      data.append('vehiclePlate', '')
      if(file) {
        data.append('file', file)
      }
      // const json = {
      //   value: serviceValue.replace(',', '.'),
      //   name: nameUser,
      //   cpf: cpfUser.replace(/\.|-/g, ""),
      //   address: endUser,
      //   email: emailUser,
      //   phone: telUser.replace(/[^0-9]+/g, ''),
      //   uf: state,
      //   city: city,
      //   serviceId: serviceId,
      //   vehiclePlate: '',
      //   dataTransaction: values,
      //   // ...values
      // }



      // const dataFile = new FormData()
      // dataFile.append('file', file)

      // const data = {
      //   json,
      // }
      // if(file) {
      //   json.file = JSON.stringify(dataFile)
      // }

      HTTP.post('services/app/PixBB/CreateCob', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${this.$store.getters.getToken}`,
        },
      }).then(res => {
        const {
          result
        } = res.data;
        this.$store.commit('setQRCodeStore', result);
        this.$router.push('/thirdyStep')
        
        this.load = false
    }).catch(err => {
        this.load = false
        this.$toast.error("Algo deu errado");
        console.log(err)
      })
    },
    sendRequestValue(value) {
      this.toGo(value)
    },
    sendRequest(value) {
      const item = this.$store.getters.getService
      if (this.getItemService.type > 6) {
        console.log('teste')
      } else {
        this.createJob(item, value)
      }
      // return
      // 
    }
  },
  watch: {
    state() {
      if (this.state) {
        this.getCity()
      }
    }
  },
  mounted() {
    this.getUf()
    this.getServices()
  }
}
</script>

<style scoped>
span {
  display: none;
  color: #cc1313;
  padding-top: 5px;
  padding-left: 5px;
}

.title {
  font-size: 1rem;
}

.arrowInButton {
  width: 9px;
}

@media screen and (max-width:600px) {
  .searchButton {
    width: 34% !important;
  }

  #row {
    min-width: 0 !important;
  }

  .titleContainer {
    width: 73% !important;
  }

  .stepTwo {
    margin-right: 4% !important;
    width: 80% !important;
  }

  .stepTwoImg {
    width: 90% !important;
  }

  .setaStep {
    margin-right: 9% !important;
  }

  .rowContainer {
    width: 85%;
  }
}

@media screen and (max-width:450px) {
  .titleContainer {
    width: 95% !important;
  }
}

@media screen and (max-width:420px) {
  .stepTwo {
    margin-right: -6% !important;
    width: 80% !important;
  }

  .stepTwoImg {
    width: 82% !important;
  }

  .setaStep {
    margin-right: 6% !important;
  }
}

@media screen and (max-width:335px) {
  /* .title {
        font-size: max(1.3vw, 16px)!important;
    } */
}

.title {
  margin: 0;
}

.titleContainer {
  margin-bottom: 3%;
  display: flex;
  justify-content: center;
  background: #F7F7FB;
  border-radius: 24px;
  width: 30%;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 45px;
  text-align: center;
  color: #465EFF;
  height: 50px;
}

.allContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3%;
  flex-direction: column;
}

.stepContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2%;
}

.stepTwo {
  margin-right: 43%;
}

.setaStep {
  margin-right: 43%;
}

.title {
  font-size: max(1.3vw, 11px);
}

.buttonContainerSearch {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width:800px) {
  .setaStep {
    margin-right: 20%;
  }

  .stepTwo {
    margin-right: 20%;
  }
}

@media screen and (max-width:600px) {
  .buttonContainerSearch {
    margin-bottom: 14% !important;
  }
}

#row {
  margin-top: 8%;
  min-width: 612px;
}

#inputForm {
  background: #FFFFFF;
  opacity: 0.8;
  border: 1px solid #1e59ae;
  border-radius: 20px;
}

label {
  font-style: normal;
  font-weight: 400;
  font-size: max(1vw, 13px);
  line-height: 24px;
  color: #797984;
  margin-top: 10px;
  margin-bottom: 0 !important;
}

.fillIn {
  margin-top: 10%;
  font-style: normal;
  font-weight: 400;
  font-size: max(1.3vw, 23px);
  line-height: 24px;
  text-align: center;
  color: #1E59AE;
}

.searchButton {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 22px;
  gap: 10px;
  background: #FFA620;
  border-radius: 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 113.2%;
  color: #FFFFFF;
  border: none;
  width: 22%;
  max-height: 49px;
  text-decoration: none;
}

.searchButton:hover {
  background: #f99500;
  transition: .7s;
}

.buttonContainerSearch {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 6%;
}

#divFormTwo {
  margin-right: 8.3%;
}
</style>
